import { createContext, ReactNode, RefObject, useContext, useRef } from 'react'
import { ToastComponent, ToastModel } from '@syncfusion/ej2-react-notifications'
import { useTranslation } from 'next-i18next'

const ToastContext = createContext<RefObject<ToastComponent> | null>(null)

const ToastContextProvider = ({ children }: { children: ReactNode }) => {
  const toastRef = useRef<ToastComponent>(null)

  return <ToastContext.Provider value={toastRef}>{children}</ToastContext.Provider>
}

const useToast = () => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const ref = useContext(ToastContext)

  const show = (
    content: ToastModel['content'],
    option?: {
      buttonText?: string
      onButtonClick?: () => void
    },
  ) => {
    if (ref && ref.current) {
      ref.current.width = 'auto'
      const toastOption: ToastModel = {
        content,
      }
      toastOption.buttons = option
        ? [
            {
              model: {
                content: option?.buttonText ?? t('confirm'),
              },
              click: (e) => {
                option?.onButtonClick?.()
                ref?.current?.hide(e.target.offsetParent)
              },
            },
          ]
        : [{}]
      ref.current.show(toastOption)
    }
  }

  return { ref, show }
}
export { useToast }

export default ToastContextProvider
