/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * rsquare-analytics API description
 * rsquare-analytics API description
 * OpenAPI spec version: 0.1.0
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  DeleteUserBuildingComparisonParams,
  DeleteUsersInterestBuildingListParams,
  DeleteUsersSearchItemListParams,
  ExceptionResponse,
  GetBuildingComparisonTargetsResponse,
  GetDowntimeNoticesResponse,
  GetErrorCodesResponse,
  GetNoticesResponse,
  GetPlanPermissionsResponse,
  GetUserBuildingComparisonParams,
  GetUserBuildingComparisonResponse,
  GetUserInterestBuildingParams,
  GetUserMeResponse,
  GetUserSubscriptionPermissionsResponse,
  GetUsersConfigurationResponse,
  GetUsersInquiresParams,
  GetUsersInquiriesLatestResponse,
  GetUsersInquiryAttachmentParams,
  GetUsersInquiryResponse,
  GetUsersInterestBuildingResponse,
  GetUsersSearchItemResponse,
  GetUsersSearchItemsParams,
  GetUsersSearchItemsResponse,
  GetUsersTableViewItemParams,
  GetUsersTableViewItemsResponse,
  GetUsersVerifyTokenResponse,
  GetVersionResponse,
  PageResponse,
  PatchBuildingComparisonRequest,
  PatchBuildingComparisonResponse,
  PatchUsersAbsolutePasswordRequest,
  PatchUsersConfigurationRequest,
  PatchUsersFcmTokenRequest,
  PatchUsersInitPasswordRequest,
  PatchUsersInquiryParams,
  PatchUsersMeRequest,
  PatchUsersPasswordRequest,
  PatchUsersSearchItemRequest,
  PatchUsersSearchItemResponse,
  PatchUsersTokenRequest,
  PatchUsersTokenResponse,
  PostBuildingComparisonRequest,
  PostBuildingComparisonResponse,
  PostNonUsersInquiryRequest,
  PostUsersInquiryParams,
  PostUsersInterestBuildingRequest,
  PostUsersInterestBuildingResponse,
  PostUsersSearchItemRequest,
  PostUsersSearchItemResponse,
  PostUsersSignInRequest,
  PostUsersSignInResponse,
  PutUsersTableViewItemsRequest,
  PutUsersTableViewItemsResponse,
  ResponseEntity,
  SendEmailAuthNumberRequest,
  VerifyDeviceResponse,
  VerifyDeviceV2Request,
  VerifyEmailAuthNumberRequest
} from './model'
import { raApiCall } from '../../utils/customInstance';
import { customFormData } from '../../utils/customFormData';



  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * 에러 코드 조회
 * @summary 에러 코드 조회
 */
export const getErrorCodes = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetErrorCodesResponse>(
      {url: `/service/error-codes`, method: 'GET'
    },
      options);
    }
  

export const getGetErrorCodesKey = () => [`/service/error-codes`] as const;

    
export type GetErrorCodesQueryResult = NonNullable<Awaited<ReturnType<typeof getErrorCodes>>>
export type GetErrorCodesQueryError = unknown

/**
 * @summary 에러 코드 조회
 */
export const useGetErrorCodes = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getErrorCodes>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetErrorCodesKey() : null);
  const swrFn = () => getErrorCodes(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 버전 체크
 * @summary 버전 체크
 */
export const getVersion = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetVersionResponse>(
      {url: `/service/version`, method: 'GET'
    },
      options);
    }
  

export const getGetVersionKey = () => [`/service/version`] as const;

    
export type GetVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getVersion>>>
export type GetVersionQueryError = unknown

/**
 * @summary 버전 체크
 */
export const useGetVersion = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getVersion>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetVersionKey() : null);
  const swrFn = () => getVersion(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 유저 빌딩 비교 목록
 * @summary 유저 빌딩 비교 목록
 */
export const getUserBuildingComparison = (
    params?: GetUserBuildingComparisonParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetUserBuildingComparisonResponse>(
      {url: `/service/v1/building-comparisons`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetUserBuildingComparisonKey = (params?: GetUserBuildingComparisonParams,) => [`/service/v1/building-comparisons`, ...(params ? [params]: [])] as const;

    
export type GetUserBuildingComparisonQueryResult = NonNullable<Awaited<ReturnType<typeof getUserBuildingComparison>>>
export type GetUserBuildingComparisonQueryError = ExceptionResponse

/**
 * @summary 유저 빌딩 비교 목록
 */
export const useGetUserBuildingComparison = <TError = ExceptionResponse>(
 params?: GetUserBuildingComparisonParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUserBuildingComparison>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUserBuildingComparisonKey(params) : null);
  const swrFn = () => getUserBuildingComparison(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 빌딩 비교 저장
 * @summary 빌딩 비교 저장
 */
export const postUserBuildingComparison = (
    postBuildingComparisonRequest: PostBuildingComparisonRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PostBuildingComparisonResponse>(
      {url: `/service/v1/building-comparisons`, method: 'POST',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: postBuildingComparisonRequest
    },
      options);
    }
  


/**
 * 빌딩 비교 다건 삭제
 * @summary 빌딩 비교 다건 삭제
 */
export const deleteUserBuildingComparison = (
    params: DeleteUserBuildingComparisonParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/building-comparisons`, method: 'DELETE',
        params
    },
      options);
    }
  


/**
 * 서비스 중지 공지 조회
 * @summary 서비스 중지 공지 조회
 */
export const getDowntimeNotices = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetDowntimeNoticesResponse>(
      {url: `/service/v1/downtime-notices`, method: 'GET'
    },
      options);
    }
  

export const getGetDowntimeNoticesKey = () => [`/service/v1/downtime-notices`] as const;

    
export type GetDowntimeNoticesQueryResult = NonNullable<Awaited<ReturnType<typeof getDowntimeNotices>>>
export type GetDowntimeNoticesQueryError = unknown

/**
 * @summary 서비스 중지 공지 조회
 */
export const useGetDowntimeNotices = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getDowntimeNotices>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetDowntimeNoticesKey() : null);
  const swrFn = () => getDowntimeNotices(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 회원 관심 부동산 조회
 * @summary 회원 관심 부동산 조회
 */
export const getUserInterestBuilding = (
    params?: GetUserInterestBuildingParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetUsersInterestBuildingResponse>(
      {url: `/service/v1/interest-buildings`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetUserInterestBuildingKey = (params?: GetUserInterestBuildingParams,) => [`/service/v1/interest-buildings`, ...(params ? [params]: [])] as const;

    
export type GetUserInterestBuildingQueryResult = NonNullable<Awaited<ReturnType<typeof getUserInterestBuilding>>>
export type GetUserInterestBuildingQueryError = unknown

/**
 * @summary 회원 관심 부동산 조회
 */
export const useGetUserInterestBuilding = <TError = unknown>(
 params?: GetUserInterestBuildingParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUserInterestBuilding>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUserInterestBuildingKey(params) : null);
  const swrFn = () => getUserInterestBuilding(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 회원 관심 부동산 등록
 * @summary 회원 관심 부동산 등록
 */
export const postUserInterestBuilding = (
    postUsersInterestBuildingRequest: PostUsersInterestBuildingRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PostUsersInterestBuildingResponse>(
      {url: `/service/v1/interest-buildings`, method: 'POST',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: postUsersInterestBuildingRequest
    },
      options);
    }
  


/**
 * 회원 관심 부동산 다건 삭제
 * @summary 회원 관심 부동산 다건 삭제
 */
export const deleteUsersInterestBuildingList = (
    params: DeleteUsersInterestBuildingListParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/interest-buildings`, method: 'DELETE',
        params
    },
      options);
    }
  


/**
 * 서비스 공지 조회
 * @summary 서비스 공지 조회
 */
export const getNotices = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetNoticesResponse>(
      {url: `/service/v1/notices`, method: 'GET'
    },
      options);
    }
  

export const getGetNoticesKey = () => [`/service/v1/notices`] as const;

    
export type GetNoticesQueryResult = NonNullable<Awaited<ReturnType<typeof getNotices>>>
export type GetNoticesQueryError = unknown

/**
 * @summary 서비스 공지 조회
 */
export const useGetNotices = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getNotices>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetNoticesKey() : null);
  const swrFn = () => getNotices(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 회원 MY필터 조회
 * @summary 회원 MY필터 조회
 */
export const getUsersSearchItems = (
    params?: GetUsersSearchItemsParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetUsersSearchItemsResponse>(
      {url: `/service/v1/search-items`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetUsersSearchItemsKey = (params?: GetUsersSearchItemsParams,) => [`/service/v1/search-items`, ...(params ? [params]: [])] as const;

    
export type GetUsersSearchItemsQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersSearchItems>>>
export type GetUsersSearchItemsQueryError = unknown

/**
 * @summary 회원 MY필터 조회
 */
export const useGetUsersSearchItems = <TError = unknown>(
 params?: GetUsersSearchItemsParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUsersSearchItems>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUsersSearchItemsKey(params) : null);
  const swrFn = () => getUsersSearchItems(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * params(JSON 타입) 컬럼은 description으로 예시 전달드립니다. <br/>name, params 모두 필수값 <br/>{ <br/>"name": "필터명" <br/>"params": {
        "areaUnit": "M2",
        "minTotalArea": "100",
        "propertyTypeCode": "PT01_2"
} <br/>}
 * @summary 회원 MY필터 등록
 */
export const postUsersSearchItem = (
    postUsersSearchItemRequest: PostUsersSearchItemRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PostUsersSearchItemResponse>(
      {url: `/service/v1/search-items`, method: 'POST',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: postUsersSearchItemRequest
    },
      options);
    }
  


/**
 * 회원 MY필터 다건 삭제
 * @summary 회원 MY필터 다건 삭제
 */
export const deleteUsersSearchItemList = (
    params: DeleteUsersSearchItemListParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/search-items`, method: 'DELETE',
        params
    },
      options);
    }
  


/**
 * 회원 테이블뷰 - 사용자 설정 항목 조회
 * @summary 회원 테이블뷰 - 사용자 설정 항목 조회
 */
export const getUsersTableViewItem = (
    params: GetUsersTableViewItemParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetUsersTableViewItemsResponse>(
      {url: `/service/v1/table-view-items`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetUsersTableViewItemKey = (params: GetUsersTableViewItemParams,) => [`/service/v1/table-view-items`, ...(params ? [params]: [])] as const;

    
export type GetUsersTableViewItemQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersTableViewItem>>>
export type GetUsersTableViewItemQueryError = unknown

/**
 * @summary 회원 테이블뷰 - 사용자 설정 항목 조회
 */
export const useGetUsersTableViewItem = <TError = unknown>(
 params: GetUsersTableViewItemParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUsersTableViewItem>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUsersTableViewItemKey(params) : null);
  const swrFn = () => getUsersTableViewItem(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 회원 테이블뷰 - 사용자 설정 항목 수정
 * @summary 회원 테이블뷰 - 사용자 설정 항목 수정
 */
export const putUsersTableViewItem = (
    putUsersTableViewItemsRequest: PutUsersTableViewItemsRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PutUsersTableViewItemsResponse>(
      {url: `/service/v1/table-view-items`, method: 'PUT',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: putUsersTableViewItemsRequest
    },
      options);
    }
  


/**
 * 이메일 인증번호 전송 API
 * @summary 이메일 인증번호 전송 API
 */
export const sendEmailAuthNumber = (
    sendEmailAuthNumberRequest: SendEmailAuthNumberRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/auths/email-auth-number`, method: 'POST',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: sendEmailAuthNumberRequest
    },
      options);
    }
  


/**
 * 이메일 인증번호 검증 API
 * @summary 이메일 인증번호 검증 API
 */
export const verifyEmailAuthNumber = (
    verifyEmailAuthNumberRequest: VerifyEmailAuthNumberRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/auths/verify-email-auth-number`, method: 'POST',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: verifyEmailAuthNumberRequest
    },
      options);
    }
  


/**
 * 빌딩 비교 저장
 * @summary 빌딩 비교 저장
 */
export const patchUserBuildingComparison = (
    comparisonId: string,
    patchBuildingComparisonRequest: PatchBuildingComparisonRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PatchBuildingComparisonResponse>(
      {url: `/service/v1/building-comparisons/${comparisonId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: patchBuildingComparisonRequest
    },
      options);
    }
  


/**
 * RestDocs 이슈로 인해 Path Parameter 타입은 param명(타입) 참고 부탁드립니다. <br>
 * @summary 회원 관심 부동산 단건 삭제
 */
export const deleteUsersInterestBuilding = (
    interestBuildingId: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/interest-buildings/${interestBuildingId}`, method: 'DELETE'
    },
      options);
    }
  


/**
 * 비회원 문의 등록
 * @summary 비회원 문의 등록
 */
export const postNonUsersInquiry = (
    postNonUsersInquiryRequest: PostNonUsersInquiryRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/non-users/inquiries`, method: 'POST',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: postNonUsersInquiryRequest
    },
      options);
    }
  


/**
 * 플랜 권한 조회
 * @summary 플랜 권한 조회
 */
export const getPlanPermissions = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetPlanPermissionsResponse>(
      {url: `/service/v1/plans/permissions`, method: 'GET'
    },
      options);
    }
  

export const getGetPlanPermissionsKey = () => [`/service/v1/plans/permissions`] as const;

    
export type GetPlanPermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof getPlanPermissions>>>
export type GetPlanPermissionsQueryError = unknown

/**
 * @summary 플랜 권한 조회
 */
export const useGetPlanPermissions = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getPlanPermissions>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetPlanPermissionsKey() : null);
  const swrFn = () => getPlanPermissions(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * pathParam - searchItemsId 컬럼은 Int타입입니다 참고 부탁드립니다.<br/>params(JSON 타입) 컬럼은 description으로 예시 전달드립니다. <br/>"params": {
        "areaUnit": "M2",
        "minTotalArea": "100",
        "propertyTypeCode": "PT01_2"
}
 * @summary 회원 MY필터 상세 조회
 */
export const getUsersSearchItem = (
    searchItemsId: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetUsersSearchItemResponse>(
      {url: `/service/v1/search-items/${searchItemsId}`, method: 'GET'
    },
      options);
    }
  

export const getGetUsersSearchItemKey = (searchItemsId: string,) => [`/service/v1/search-items/${searchItemsId}`] as const;

    
export type GetUsersSearchItemQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersSearchItem>>>
export type GetUsersSearchItemQueryError = ExceptionResponse

/**
 * @summary 회원 MY필터 상세 조회
 */
export const useGetUsersSearchItem = <TError = ExceptionResponse>(
 searchItemsId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUsersSearchItem>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(searchItemsId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUsersSearchItemKey(searchItemsId) : null);
  const swrFn = () => getUsersSearchItem(searchItemsId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * pathParam - searchItemsId 컬럼은 Int타입입니다 참고 부탁드립니다.<br/>
 * @summary 회원 MY필터 단건 삭제
 */
export const deleteUsersSearchItem = (
    searchItemsId: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/search-items/${searchItemsId}`, method: 'DELETE'
    },
      options);
    }
  


/**
 * pathParam - searchItemsId 컬럼은 Int타입입니다 참고 부탁드립니다.<br/>params(JSON 타입) 컬럼은 description으로 예시 전달드립니다. <br/>{ <br/>"name": "필터명" <br/>"params": {
        "areaUnit": "M2",
        "minTotalArea": "100",
        "propertyTypeCode": "PT01_2"
} <br/>}
 * @summary 회원 MY필터 수정
 */
export const patchUsersSearchItem = (
    searchItemsId: string,
    patchUsersSearchItemRequest: PatchUsersSearchItemRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PatchUsersSearchItemResponse>(
      {url: `/service/v1/search-items/${searchItemsId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: patchUsersSearchItemRequest
    },
      options);
    }
  


/**
 * 회원 설정 조회
 * @summary 회원 설정 조회
 */
export const getUserConfiguration = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetUsersConfigurationResponse>(
      {url: `/service/v1/users/configuration`, method: 'GET'
    },
      options);
    }
  

export const getGetUserConfigurationKey = () => [`/service/v1/users/configuration`] as const;

    
export type GetUserConfigurationQueryResult = NonNullable<Awaited<ReturnType<typeof getUserConfiguration>>>
export type GetUserConfigurationQueryError = unknown

/**
 * @summary 회원 설정 조회
 */
export const useGetUserConfiguration = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUserConfiguration>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUserConfigurationKey() : null);
  const swrFn = () => getUserConfiguration(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 회원 설정 수정
 * @summary 회원 설정 수정
 */
export const patchUsersConfiguration = (
    patchUsersConfigurationRequest: PatchUsersConfigurationRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/users/configuration`, method: 'PATCH',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: patchUsersConfigurationRequest
    },
      options);
    }
  


/**
 * 회원 FCM 토큰값 삭제
 * @summary 회원 FCM 토큰값 삭제
 */
export const deleteUsersFcmTokens = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/users/fcm-tokens`, method: 'DELETE'
    },
      options);
    }
  


/**
 * 회원 FCM 토큰값 생성 및 갱신
 * @summary 회원 FCM 토큰값 생성 및 갱신
 */
export const patchUsersFcmTokens = (
    patchUsersFcmTokenRequest: PatchUsersFcmTokenRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/users/fcm-tokens`, method: 'PATCH',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: patchUsersFcmTokenRequest
    },
      options);
    }
  


/**
 * 임시비밀번호 사용시 최초 비밀번호 변경
 * @summary 임시비밀번호 사용시 최초 비밀번호 변경
 */
export const patchUsersInitPassword = (
    patchUsersInitPasswordRequest: PatchUsersInitPasswordRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/users/init-password`, method: 'PATCH',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: patchUsersInitPasswordRequest
    },
      options);
    }
  


/**
 * 회원 문의 목록 조회
 * @summary 회원 문의 목록 조회
 */
export const getUsersInquires = (
    params?: GetUsersInquiresParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PageResponse>(
      {url: `/service/v1/users/inquiries`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetUsersInquiresKey = (params?: GetUsersInquiresParams,) => [`/service/v1/users/inquiries`, ...(params ? [params]: [])] as const;

    
export type GetUsersInquiresQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersInquires>>>
export type GetUsersInquiresQueryError = unknown

/**
 * @summary 회원 문의 목록 조회
 */
export const useGetUsersInquires = <TError = unknown>(
 params?: GetUsersInquiresParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUsersInquires>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUsersInquiresKey(params) : null);
  const swrFn = () => getUsersInquires(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * RestDocs 이슈로 인해 파일 첨부가 포함된 API는 Description 참고 부탁드립니다. <br>Content-Type : multipart/form-data 으로 요청주시면 됩니다. <br>contents는 필수값입니다.inquiryItems는 List형태 입니다.
 * @summary 문의 등록
 */
export const postUsersInquiry = (
    params?: PostUsersInquiryParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<ResponseEntity>(
      {url: `/service/v1/users/inquiries`, method: 'POST',
        params
    },
      options);
    }
  


/**
 * 회원 마이페이지 조회
 * @summary 회원 마이페이지 조회
 */
export const getUserMe = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetUserMeResponse>(
      {url: `/service/v1/users/me`, method: 'GET'
    },
      options);
    }
  

export const getGetUserMeKey = () => [`/service/v1/users/me`] as const;

    
export type GetUserMeQueryResult = NonNullable<Awaited<ReturnType<typeof getUserMe>>>
export type GetUserMeQueryError = ExceptionResponse

/**
 * @summary 회원 마이페이지 조회
 */
export const useGetUserMe = <TError = ExceptionResponse>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUserMe>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUserMeKey() : null);
  const swrFn = () => getUserMe(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 회원 마이페이지 수정
 * @summary 회원 마이페이지 수정
 */
export const patchUsersMe = (
    patchUsersMeRequest: PatchUsersMeRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/users/me`, method: 'PATCH',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: patchUsersMeRequest
    },
      options);
    }
  


/**
 * 로그인 후 비밀번호 변경
 * @summary 로그인 후 비밀번호 변경
 */
export const patchUsersPassword = (
    patchUsersPasswordRequest: PatchUsersPasswordRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/users/password`, method: 'PATCH',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: patchUsersPasswordRequest
    },
      options);
    }
  


/**
 * 로그아웃
 * @summary 로그아웃
 */
export const postUsersSignOut = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/users/sign-out`, method: 'POST'
    },
      options);
    }
  


/**
 * JWT 토큰 갱신
 * @summary JWT 토큰 갱신
 */
export const patchUsersToken = (
    patchUsersTokenRequest: PatchUsersTokenRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PatchUsersTokenResponse>(
      {url: `/service/v1/users/token`, method: 'PATCH',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: patchUsersTokenRequest
    },
      options);
    }
  


/**
 * JWT 토큰 검증
 * @summary JWT 토큰 검증
 */
export const getUsersVerifyToken = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetUsersVerifyTokenResponse>(
      {url: `/service/v1/users/verify-token`, method: 'GET'
    },
      options);
    }
  

export const getGetUsersVerifyTokenKey = () => [`/service/v1/users/verify-token`] as const;

    
export type GetUsersVerifyTokenQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersVerifyToken>>>
export type GetUsersVerifyTokenQueryError = ExceptionResponse

/**
 * @summary JWT 토큰 검증
 */
export const useGetUsersVerifyToken = <TError = ExceptionResponse>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUsersVerifyToken>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUsersVerifyTokenKey() : null);
  const swrFn = () => getUsersVerifyToken(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 로그인(유저의 JWT 토큰 생성) V2
 * @summary 로그인(유저의 JWT 토큰 생성) V2
 */
export const postUsersSignInV2 = (
    postUsersSignInRequest: PostUsersSignInRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PostUsersSignInResponse>(
      {url: `/service/v2/users/sign-in`, method: 'POST',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: postUsersSignInRequest
    },
      options);
    }
  


/**
 * 기기인증 요청 API - V2
 * @summary 기기인증 요청 API - V2
 */
export const verifyDeviceV2 = (
    verifyDeviceV2Request: VerifyDeviceV2Request,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<VerifyDeviceResponse>(
      {url: `/service/v2/users/verify-device`, method: 'POST',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: verifyDeviceV2Request
    },
      options);
    }
  


/**
 * 빌딩 비교 대상 건물 조회
 * @summary 빌딩 비교 대상 건물 조회
 */
export const getBuildingComparisonTargets = (
    comparisonId: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetBuildingComparisonTargetsResponse>(
      {url: `/service/v1/building-comparisons/${comparisonId}/targets`, method: 'GET'
    },
      options);
    }
  

export const getGetBuildingComparisonTargetsKey = (comparisonId: string,) => [`/service/v1/building-comparisons/${comparisonId}/targets`] as const;

    
export type GetBuildingComparisonTargetsQueryResult = NonNullable<Awaited<ReturnType<typeof getBuildingComparisonTargets>>>
export type GetBuildingComparisonTargetsQueryError = ExceptionResponse

/**
 * @summary 빌딩 비교 대상 건물 조회
 */
export const useGetBuildingComparisonTargets = <TError = ExceptionResponse>(
 comparisonId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getBuildingComparisonTargets>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(comparisonId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetBuildingComparisonTargetsKey(comparisonId) : null);
  const swrFn = () => getBuildingComparisonTargets(comparisonId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 비밀번호 찾기를 통한 비밀번호 변경
 * @summary 비밀번호 찾기를 통한 비밀번호 변경
 */
export const patchUsersAbsolutePassword = (
    patchUsersAbsolutePasswordRequest: PatchUsersAbsolutePasswordRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/users/absolute/password`, method: 'PATCH',
      headers: {'Content-Type': 'application/json;charset=UTF-8', },
      data: patchUsersAbsolutePasswordRequest
    },
      options);
    }
  


/**
 * 회원용 마지막 문의 내용 불러오기
 * @summary 회원용 마지막 문의 내용 불러오기
 */
export const getUsersInquiriesLatest = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetUsersInquiriesLatestResponse>(
      {url: `/service/v1/users/inquiries/latest`, method: 'GET'
    },
      options);
    }
  

export const getGetUsersInquiriesLatestKey = () => [`/service/v1/users/inquiries/latest`] as const;

    
export type GetUsersInquiriesLatestQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersInquiriesLatest>>>
export type GetUsersInquiriesLatestQueryError = ExceptionResponse

/**
 * @summary 회원용 마지막 문의 내용 불러오기
 */
export const useGetUsersInquiriesLatest = <TError = ExceptionResponse>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUsersInquiriesLatest>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUsersInquiriesLatestKey() : null);
  const swrFn = () => getUsersInquiriesLatest(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 답변이 없는 문의에서는 answer: null로 전달됩니다.
 * @summary 회원 문의 상세 조회
 */
export const getUsersInquiry = (
    raInquiryId: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetUsersInquiryResponse>(
      {url: `/service/v1/users/inquiries/${raInquiryId}`, method: 'GET'
    },
      options);
    }
  

export const getGetUsersInquiryKey = (raInquiryId: string,) => [`/service/v1/users/inquiries/${raInquiryId}`] as const;

    
export type GetUsersInquiryQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersInquiry>>>
export type GetUsersInquiryQueryError = unknown

/**
 * @summary 회원 문의 상세 조회
 */
export const useGetUsersInquiry = <TError = unknown>(
 raInquiryId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUsersInquiry>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raInquiryId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUsersInquiryKey(raInquiryId) : null);
  const swrFn = () => getUsersInquiry(raInquiryId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 회원 문의 삭제
 * @summary 회원 문의 삭제
 */
export const deleteUsersInquiry = (
    raInquiryId: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/users/inquiries/${raInquiryId}`, method: 'DELETE'
    },
      options);
    }
  


/**
 * RestDocs 이슈로 인해 파일 첨부가 포함된 API는 Description 참고 부탁드립니다. <br>Content-Type : multipart/form-data 으로 요청주시면 됩니다. <br>contents는 필수값입니다.inquiryItems는 List형태 입니다.
 * @summary 회원 문의 수정
 */
export const patchUsersInquiry = (
    raInquiryId: string,
    params?: PatchUsersInquiryParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<ResponseEntity>(
      {url: `/service/v1/users/inquiries/${raInquiryId}`, method: 'PATCH',
        params
    },
      options);
    }
  


/**
 * 회원 권한 조회
 * @summary 회원 권한 조회
 */
export const getUserSubscriptionsPermissions = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetUserSubscriptionPermissionsResponse>(
      {url: `/service/v1/users/subscriptions/permissions`, method: 'GET'
    },
      options);
    }
  

export const getGetUserSubscriptionsPermissionsKey = () => [`/service/v1/users/subscriptions/permissions`] as const;

    
export type GetUserSubscriptionsPermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserSubscriptionsPermissions>>>
export type GetUserSubscriptionsPermissionsQueryError = unknown

/**
 * @summary 회원 권한 조회
 */
export const useGetUserSubscriptionsPermissions = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUserSubscriptionsPermissions>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUserSubscriptionsPermissionsKey() : null);
  const swrFn = () => getUserSubscriptionsPermissions(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 회원용 문의 상세 첨부파일 다운로드
 * @summary 회원용 문의 상세 첨부파일 다운로드
 */
export const getUsersInquiryAttachment = (
    raInquiryId: string,
    params: GetUsersInquiryAttachmentParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/service/v1/users/inquiries/${raInquiryId}/attachments`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetUsersInquiryAttachmentKey = (raInquiryId: string,
    params: GetUsersInquiryAttachmentParams,) => [`/service/v1/users/inquiries/${raInquiryId}/attachments`, ...(params ? [params]: [])] as const;

    
export type GetUsersInquiryAttachmentQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersInquiryAttachment>>>
export type GetUsersInquiryAttachmentQueryError = ExceptionResponse

/**
 * @summary 회원용 문의 상세 첨부파일 다운로드
 */
export const useGetUsersInquiryAttachment = <TError = ExceptionResponse>(
 raInquiryId: string,
    params: GetUsersInquiryAttachmentParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUsersInquiryAttachment>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raInquiryId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUsersInquiryAttachmentKey(raInquiryId,params) : null);
  const swrFn = () => getUsersInquiryAttachment(raInquiryId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


