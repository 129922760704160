import { ReactNode, forwardRef } from 'react'
import { TooltipModel } from '@syncfusion/ej2-popups/src/tooltip/tooltip-model'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { css, styled } from '@xstyled/styled-components'
import { TooltipEventArgs } from '@syncfusion/ej2-popups/src/tooltip/tooltip'

export interface TooltipProps {
  content: TooltipModel['content']
  children: ReactNode
  width?: TooltipModel['width']
  target?: TooltipModel['target']
  position?: TooltipModel['position']
  tipPointerPosition?: TooltipModel['tipPointerPosition']
  beforeOpen?: TooltipModel['beforeOpen']
  align?: 'left' | 'center' | 'right'
  color?: 'black' | 'white'
  opensOn?: TooltipModel['opensOn']
  borderRadius?: string
}

// TODO: 맵 컨트롤 이외에 border-radius가 필요한 곳이 있는지 확인 필요

const Tooltip = forwardRef<TooltipComponent, TooltipProps>(
  (
    {
      content,
      width,
      tipPointerPosition,
      target,
      position = 'BottomCenter',
      beforeOpen,
      align = 'center',
      color = 'black',
      opensOn,
      children,
      borderRadius,
    },
    ref,
  ) => {
    const getOffsetX = () => {
      if (position === 'LeftCenter') {
        return -3
      } else if (position === 'RightCenter') {
        return 3
      }
      return undefined
    }

    const getOffsetY = () => {
      if (position === 'TopCenter') {
        return -3
      } else if (position === 'BottomCenter') {
        return 3
      }
      return undefined
    }

    const handleAfterOpen = (args: TooltipEventArgs) => {
      const tooltipRect = args.element.getBoundingClientRect()

      if (tooltipRect.left === 0 || tooltipRect.width + tooltipRect.left === window.innerWidth) {
        args.element.className = `e-tooltip-wrap ${color}`
      }
    }

    return (
      <StyledTooltip
        ref={ref}
        cssClass={`${align ? `align-${align}` : ''} ${color}`}
        content={content}
        width={width}
        tipPointerPosition={tipPointerPosition}
        target={target}
        position={position}
        beforeOpen={beforeOpen}
        afterOpen={handleAfterOpen}
        offsetX={getOffsetX()}
        offsetY={getOffsetY()}
        opensOn={opensOn}
        borderRadius={borderRadius}
      >
        {children}
      </StyledTooltip>
    )
  },
)

Tooltip.displayName = 'Tooltip'

const StyledTooltip = styled(TooltipComponent)<{ borderRadius?: string }>`
  overflow: auto;
  ${({ borderRadius }) => css({ borderRadius: borderRadius })}
  font-family: inherit;
`

export default Tooltip
