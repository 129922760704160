import {
  SearchBuildingOut,
  SearchClientOut,
  SearchLandOut,
  SearchRegionOut,
  SearchSubwayOut,
  SearchTotalDataV1SearchGetParams,
} from '~/libs/apis/data/model'

export type SearchIndexType = SearchRegionOut | SearchBuildingOut | SearchLandOut | SearchSubwayOut | SearchClientOut

export const isSearchRegionOut = (value: SearchIndexType): value is SearchRegionOut => 'regionCode' in value
export const isSearchLandOut = (value: SearchIndexType): value is SearchLandOut => 'pnu' in value
export const iseSearchSubwayOut = (value: SearchIndexType): value is SearchSubwayOut => 'subwayLineCode' in value

export const isSearchBuildingOut = (value: SearchIndexType): value is SearchBuildingOut => 'raId' in value
export const isSearchClientOut = (value: SearchIndexType): value is SearchClientOut => 'clientId' in value

type SearchSizeRequestParams = Required<
  Pick<SearchTotalDataV1SearchGetParams, 'buildingSize' | 'landSize' | 'regionSize' | 'subwaySize' | 'clientSize'>
>

export const BUILDING_SEARCH_SIZE: SearchSizeRequestParams = {
  buildingSize: 15,
  landSize: 3,
  regionSize: 3,
  subwaySize: 5,
  clientSize: 0,
}

export const LAND_SEARCH_SIZE: SearchSizeRequestParams = {
  buildingSize: 0,
  landSize: 10,
  regionSize: 3,
  subwaySize: 5,
  clientSize: 0,
}

export const TENANT_SEARCH_SIZE: SearchSizeRequestParams = {
  buildingSize: 10,
  landSize: 0,
  regionSize: 3,
  subwaySize: 0,
  clientSize: 10,
}

export const REGISTRATION_SEARCH_SIZE: SearchSizeRequestParams = {
  buildingSize: 0,
  landSize: 10,
  regionSize: 3,
  subwaySize: 0,
  clientSize: 0,
}
