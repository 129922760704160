/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.3.1
 */

export type DemandflowLesseeGroupType = typeof DemandflowLesseeGroupType[keyof typeof DemandflowLesseeGroupType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DemandflowLesseeGroupType = {
  boe: 'boe',
  lesseeSector: 'lesseeSector',
  lesseeType: 'lesseeType',
} as const;
