import {
  useGetOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGet,
  useGetOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGet,
  useOfficeDetailDataV1BuildingsOfficeRaIdGet,
  useOfficeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGet,
  useOfficeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGet,
  useOfficeLesseeDataV1BuildingsOfficeLesseeGet,
  useOfficeTradeDataV1BuildingsOfficeTradeGet,
} from '~/libs/apis/data/buildings-office/buildings-office'
import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'
import useOfficeStackingPlan from '~/templates/common/view/stacking-plan/useOfficeStackingPlan'
import {
  useDemolishDataV1GovDemolishGet,
  useGetLandUsePlanDataV1GovLandUsePlanGet,
  useLandChangeOwnerDataV1GovLandChangeOwnerGet,
  useLandInfoDataV1GovLandGet,
  useLandPriceDataV1GovLandPriceGet,
  useLedgerFloorDataV1GovLedgerFloorGet,
  useLedgerOutlineDataV1GovLedgerOutlineGet,
  useLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet,
  useLedgerPossessionDataV1GovLedgerPossessionGet,
  usePermissionDataV1GovPermissionGet,
} from '~/libs/apis/data/gov/gov'
import { isNotNil } from '@toss/utils'
import { useGetRegistrations, useIsBuilding } from '~/libs/apis/registration/api'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import { useOfficeGroupDataV1BuildingsGroupGroupRaIdGet } from '~/libs/apis/data/buildings/buildings'

const pageParam = { pageNum: 1, pageSize: 5 }

const useOfficeFullDetailLoading = (raId: string) => {
  const isFull = useDetailInfo().detailType === 'full'
  const { lastUpdate } = useLastUpdateDate()

  // TODO: 공통 hook으로 쓰일수 있을지 검토필요
  const pastFiveYearPeriod = {
    minYear: (+lastUpdate.year - 5 + 1).toString(),
    minQuarter: lastUpdate.quarter,
    maxYear: lastUpdate.year,
    maxQuarter: lastUpdate.quarter,
  }

  // 건물
  const { data: detail, isLoading: isDetailLoading } = useOfficeDetailDataV1BuildingsOfficeRaIdGet(raId)
  const { isLoading: isGroupLoading } = useOfficeGroupDataV1BuildingsGroupGroupRaIdGet(detail?.groupRaId!, {
    swr: { enabled: isNotNil(detail?.groupRaId) },
  })

  const pnu = detail?.pnu
  const hasPnu = isNotNil(pnu)

  const { data: legderOutline, isLoading: isLedgerOutlineLoading } = useLedgerOutlineDataV1GovLedgerOutlineGet(
    { pnu: pnu! },
    {
      swr: {
        enabled: hasPnu && isFull,
      },
    },
  )
  const ledgerMgmBldrgstPk = legderOutline?.data?.[0]?.mgmBldrgstPk
  const ledgerInfoParams = { mgmBldrgstPk: ledgerMgmBldrgstPk!, pageNum: 1, pageSize: 10 }
  const { isLoading: isLedgerOutlineSummaryLoading } = useLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet(
    { pnu: pnu! },
    { swr: { enabled: hasPnu && isFull, shouldRetryOnError: false } },
  )
  const { isLoading: isLedgerPossessionLoading } = useLedgerPossessionDataV1GovLedgerPossessionGet(ledgerInfoParams, {
    swr: {
      enabled: isNotNil(ledgerMgmBldrgstPk) && isFull,
    },
  })
  const { isLoading: isLedgerFloorLoading } = useLedgerFloorDataV1GovLedgerFloorGet(ledgerInfoParams, {
    swr: {
      enabled: isNotNil(ledgerMgmBldrgstPk) && isFull,
    },
  })
  const { isLoading: isPermissionLoading } = usePermissionDataV1GovPermissionGet(
    { pnu: pnu!, ...pageParam, pageSize: 10 },
    {
      swr: {
        enabled: hasPnu && isFull,
      },
    },
  )
  const { isLoading: isDemolishLoading } = useDemolishDataV1GovDemolishGet(
    { pnu: pnu!, pageNum: 1, pageSize: 10 },
    {
      swr: {
        enabled: hasPnu && isFull,
      },
    },
  )

  // 토지
  const { data: lands, isLoading: isLandLoading } = useLandInfoDataV1GovLandGet({ raId })
  const landsPnu = lands?.at(0)?.pnu
  const { isLoading: isLandUsePlanLoading } = useGetLandUsePlanDataV1GovLandUsePlanGet(
    { pnu: landsPnu! },
    {
      swr: {
        enabled: isNotNil(landsPnu),
      },
    },
  )
  const { isLoading: isLandChangeOwnerLoading } = useLandChangeOwnerDataV1GovLandChangeOwnerGet(
    { pnu: landsPnu!, ...pageParam },
    {
      swr: {
        enabled: isNotNil(landsPnu),
      },
    },
  )
  const { isLoading: isLandPriceChartLoading } = useLandPriceDataV1GovLandPriceGet(
    {
      pnu: landsPnu!,
    },
    {
      swr: {
        enabled: isNotNil(landsPnu) && isFull,
      },
    },
  )

  // 임대차
  const { isLoading: isLeaseMarketTrendLoading } = useOfficeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGet({
    raId,
    valueType: 'rentFee',
    ...pastFiveYearPeriod,
  })
  const { isLoading: isPrdInfoLoading } = useGetOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGet(
    raId,
    pastFiveYearPeriod,
  )
  const {
    dongResponse: { isLoading: isDongLoading },
    stackingPlanResponse: { isLoading: isStackingPlanLoading },
  } = useOfficeStackingPlan({
    raId,
    year: lastUpdate.year,
    quarter: lastUpdate.quarter,
    enabled: isFull,
  })
  const { isLoading: isLesseeChangeLoading } = useOfficeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGet(
    raId,
    {
      year: lastUpdate.year,
      quarter: lastUpdate.quarter,
    },
    { swr: { enabled: isFull } },
  )
  const { isLoading: isLesseeLoading } = useOfficeLesseeDataV1BuildingsOfficeLesseeGet(
    { raId, ...pageParam, year: lastUpdate.year, quarter: lastUpdate.quarter },
    {
      swr: { keepPreviousData: true },
    },
  )

  // 거래
  const { isLoading: isTradeLoading } = useOfficeTradeDataV1BuildingsOfficeTradeGet({
    raId: raId,
    ...pageParam,
  })

  // 등기
  const { isLoading: isRegistrationsLoading } = useGetRegistrations(
    { pnu: pnu! },
    {
      swr: { enabled: hasPnu, shouldRetryOnError: false },
    },
  )
  const { isLoading: isBuildingLoading } = useIsBuilding({ pnu: pnu! }, { swr: { enabled: hasPnu } })

  const { isLoading: isPrdMovementLoading } = useGetOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGet(
    raId,
    {},
    {
      swr: { enabled: isNotNil(raId) },
    },
  )

  return (
    isDetailLoading ||
    isGroupLoading ||
    isLandLoading ||
    isLedgerOutlineLoading ||
    isLedgerOutlineSummaryLoading ||
    isLedgerPossessionLoading ||
    isLedgerFloorLoading ||
    isPermissionLoading ||
    isDemolishLoading ||
    isLandUsePlanLoading ||
    isLandChangeOwnerLoading ||
    isLandPriceChartLoading ||
    isLeaseMarketTrendLoading ||
    isPrdInfoLoading ||
    isDongLoading ||
    isLesseeChangeLoading ||
    isStackingPlanLoading ||
    isLesseeLoading ||
    isTradeLoading ||
    isRegistrationsLoading ||
    isBuildingLoading ||
    isPrdMovementLoading
  )
}

export default useOfficeFullDetailLoading
