import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import DetailTable, { DetailTableType } from '~/components/Table/DetailTable'
import { formatElevatorInfo, formatFloorInfo, formatVacancyRate } from '~/libs/utils/format'
import { OfficeOut } from '~/libs/apis/data/model'
import { formatDateString, formatDateYmd, formatDateYq } from '~/libs/utils/date'
import useConstructStatus from '~/libs/hooks/useConstructStatus'
import { useAreaFormat, usePerUnitFormat } from '~/libs/hooks/useFormat'
import OfficeSubwayData from '~/templates/place/detail/OfficeSubwayData'
import { commaizeNumber } from '@toss/utils'

interface BuildingDetailTableProps {
  data: OfficeOut
}

const BuildingDetailTable: FC<BuildingDetailTableProps> = ({ data }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: unit } = useTranslation('common', { keyPrefix: 'unit' })
  const { constructStatus } = useConstructStatus(data.constructStatusCode)
  const areaFormatter = useAreaFormat()
  const unitFormatter = usePerUnitFormat()
  const typicalFloorRentFee = unitFormatter(data.rentFeePerM2)
  const recentTrade = `${data?.recentTradeDateYmd ? formatDateString(data.recentTradeDateYmd, 'yyyy-MM') : '-'} ${
    data?.recentTradePricePerM2 ? `(${unitFormatter(data.recentTradePricePerM2, { format: 'man' })})` : ''
  }`

  const commonData: DetailTableType[] = [
    {
      column: t('construction_year'),
      content: data.constructDateY ? unit('year', { year: data.constructDateY }) : '-',
    },
    {
      column: t('remodeling_year'),
      content: data.remodelingDateY ? unit('year', { year: data.remodelingDateY }) : '-',
    },
    {
      column: t('plat_area'),
      content: areaFormatter(data.platArea, { rounded: 'n2' }),
    },
    {
      column: t('architecture_area'),
      content: areaFormatter(data.archArea, { rounded: 'n2' }),
    },
    { column: t('bc_rat'), content: formatVacancyRate(data.bcRat) },
    {
      column: t('gra_py'),
      content: areaFormatter(data.totalArea, { rounded: 'n2' }),
    },
    { column: t('vl_rat'), content: formatVacancyRate(data.vlRat) },
    { column: t('floor_cnt'), content: formatFloorInfo(unit, data.floorCnt, data.baseFloorCnt) },
    {
      column: t('total_park_cnt'),
      content: data.totalParkCnt ? unit('dae_count', { number: commaizeNumber(data.totalParkCnt) }) : '-',
    },
    {
      column: t('elevator_cnt'),
      content: formatElevatorInfo(unit, data.customerElevatorCnt, data.emergencyElevatorCnt),
    },
    {
      column: t('subway_station'),
      content: <OfficeSubwayData data={data} />,
      oneLine: true,
    },
    { column: `${t('owner')} (${t('rsquare_research')})`, content: data.ownerName, oneLine: true },
    { column: `${t('owner')} (${t('registration')})`, content: data.registrationOwnerSummary, oneLine: true },
  ]

  const completionData: DetailTableType[] = [
    {
      column: t('typical_floor_rent_fee'),
      content: typicalFloorRentFee,
    },
    { column: t('recent_trade'), content: recentTrade },
    { column: t('regist_division'), content: data.registGubunName },
    {
      column: t('main_purpose'),
      content: data.mainPurpose ?? '-',
    },
    ...commonData,
  ]

  const scheduledData: DetailTableType[] = [
    { column: t('regist_division'), content: data.registGubunName },
    {
      column: t('main_purpose'),
      content: data.mainPurpose ?? '-',
    },
    {
      column: t('permission_construction_date'),
      content: formatDateYmd(data.permissionConstructionDateYmd),
    },
    {
      column: t('real_construction_date'),
      content: formatDateYmd(data.realConstructionDateYmd),
    },
    {
      column: t('supply_scheduled_time'),
      content: formatDateYq(data.scheduledSupplyDateYq),
    },
    {
      column: t('plat_area'),
      content: areaFormatter(data.platArea, { rounded: 'n2' }),
    },
    {
      column: t('architecture_area'),
      content: areaFormatter(data.archArea, { rounded: 'n2' }),
    },
    {
      column: t('gra_py'),
      content: areaFormatter(data.totalArea, { rounded: 'n2' }),
    },
    { column: t('bc_rat'), content: formatVacancyRate(data.bcRat) },
    { column: t('vl_rat'), content: formatVacancyRate(data.vlRat) },

    { column: t('floor_cnt'), content: formatFloorInfo(unit, data.floorCnt, data.baseFloorCnt) },
    {
      column: t('subway_station'),
      content: <OfficeSubwayData data={data} />,
    },
    { column: t('fiduciary'), content: data.fiduciary },
    { column: t('implementer'), content: data.implementer },
    { column: `${t('owner')} (${t('rsquare_research')})`, content: data.ownerName, oneLine: true },
    { column: `${t('owner')} (${t('registration')})`, content: data.registrationOwnerSummary, oneLine: true },
  ]

  const demolishData: DetailTableType[] = [
    {
      column: t('demolish_year'),
      content: data.demolishDateY ? unit('year', { year: data.demolishDateY }) : '-',
      oneLine: true,
    },
    { column: t('recent_trade'), content: recentTrade, oneLine: true },
    { column: t('regist_division'), content: data.registGubunName },
    {
      column: t('main_purpose'),
      content: data.mainPurpose ?? '-',
    },
    ...commonData,
  ]
  const getBuildingData = () => {
    switch (constructStatus) {
      case 'supplyCompleted':
        return completionData
      case 'supplyScheduled':
        return scheduledData
      case 'demolish':
        return demolishData
      default:
        return completionData
    }
  }

  return <DetailTable data={getBuildingData()} />
}

export default BuildingDetailTable
