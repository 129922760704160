import { ReactNode } from 'react'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import XGraphic from '~/assets/images/icons/x-graphic.svg'
import { useTranslation } from 'next-i18next'

interface FullScreenModalProps {
  onClose: () => void
  children: ReactNode
}

const FullScreenModal = ({ onClose, children }: FullScreenModalProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      zIndex={1100000000}
      width="100%"
      height="100%"
      backgroundColor="rgba(0,0,0,0.8)"
    >
      <Box
        position="fixed"
        zIndex={10}
        left="50%"
        transform
        translateX="-50%"
        bottom="30px"
        userSelect="none"
        as="button"
        outline={{ focus: 'none' }}
        borderRadius="51px"
        py="10px"
        px="20px"
        backgroundColor="green-600"
        color="system-white"
        display="flex"
        alignItems="center"
        gap={2}
        boxShadow="black-06"
        onClick={onClose}
      >
        <Typography color="system-white" variant="subtitle" fontWeight="bold">
          {t('close_screen')}
        </Typography>
        <XGraphic width={14} height={14} />
      </Box>
      {children}
    </Box>
  )
}

export default FullScreenModal
