import { KeyboardEvent, useState } from 'react'

const keyEvents = ['ArrowDown', 'ArrowUp', 'Enter']
type KeyEventType = (typeof keyEvents)[number]

interface SearchControlParams {
  length: number
  onEnter?: (focusIndex: number) => void
}

interface SearchKeyControls {
  focusIndex: number | null
  onKeyEvent: (e: KeyboardEvent) => void
  onClear: () => void
}

const useControlSearchKey = ({ length, onEnter }: SearchControlParams): SearchKeyControls => {
  const [focusIndex, setFocusIndex] = useState<null | number>(null)

  const KeyEvents: Record<KeyEventType, (event: KeyboardEvent) => void> = {
    ArrowDown: () => {
      setFocusIndex((prev) => {
        if (focusIndex === null || focusIndex >= length! - 1) {
          return 0
        }
        return (prev ?? 0) + 1
      })
    },
    ArrowUp: () => {
      setFocusIndex((prev) => {
        if (focusIndex === null || focusIndex === 0) {
          return length - 1
        }
        return (prev ?? 0) - 1
      })
    },
    Enter: (event) => {
      if (focusIndex === null) {
        event.preventDefault()
        return
      }
      onEnter?.(focusIndex)
    },
  }

  const onKeyEvent = (event: KeyboardEvent) => {
    if (event.nativeEvent.isComposing) {
      return
    }
    if (length === 0) {
      return
    }
    if (keyEvents.includes(event.key)) {
      KeyEvents[event.key](event)
    } else {
      onClear()
    }
  }

  const onClear = () => setFocusIndex(null)

  return {
    focusIndex,
    onKeyEvent,
    onClear,
  }
}

export default useControlSearchKey
