import { raApiCall } from '~/libs/utils/customInstance'
import {
  GetUsersInquiryResponseInquiry,
  GetUsersInquiryResponseInquiryInquiryItemsItem,
  PostNonUsersInquiryRequest,
  PostUsersInquiryParams,
  ResponseEntity,
} from '~/libs/apis/service/model'
import { customFormData } from '~/libs/utils/customFormData'
import { isNotNil } from '@toss/utils'
import { convertNewlineToJSX } from '@toss/react'
import ReactDOMServer from 'react-dom/server'

const USER_PARAM_KEY = ['targetType', 'raId', 'pnu', 'inquiryType', 'inquiryItems'] as (keyof PostUsersInquiryParams)[]
const GUEST_PARAM_KEY = ['targetType', 'inquiryType', 'name', 'email'] as (keyof PostNonUsersInquiryRequest)[]
const ARRAY_TYPE_KEY = ['inquiryItems']

export const UserInquiryType: Record<string, string> = {
  data: 'DATA_INQUIRY',
  usage: 'USAGE_INQUIRY',
  error: 'ERROR_REPORT',
  other: 'OTHER_INQUIRY',
}

export const GuestInquiryType = {
  usage: 'USAGE_INQUIRY',
  other: 'OTHER_INQUIRY',
}

export const InquiryStatusType: Record<string, string> = {
  RECEIVED: 'received',
  IN_PROGRESS: 'in_progress',
  ANSWERED: 'answered',
}

export const InquiryItemType = {
  BUILDING_INFORMATION: 'building_information',
  LEASE_VACANCY: 'lease_vacancy',
  PURCHASE_SALE: 'purchase_sale',
  PERMITS_DEVELOPMENT_PLANS: 'permits_development_plans',
  ETC: 'etc',
}

export type InquiryItems = keyof typeof InquiryItemType

// FIXME: 파일첨부가 필요한 api 들이 swagger docs 오류로 content-type에 multipart/form-data가 셋팅되지 않음
export const postInquiryForGuest = async (formData: FormData) => {
  return await raApiCall<ResponseEntity>({
    url: `/service/v1/non-users/inquiries`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
}

export const postInquiryForUser = async (formData: FormData) => {
  return await raApiCall<ResponseEntity>({
    url: `/service/v1/users/inquiries`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
}

export const patchInquiryForUser = async (raInquiryId: number, formData: FormData) => {
  return raApiCall<ResponseEntity>({
    url: `/service/v1/users/inquiries/${raInquiryId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
}

const formatStrToJSXString = (str: string) => {
  const jsxArr = convertNewlineToJSX(str)
  return jsxArr.map((element) => ReactDOMServer.renderToString(element)).join('')
}

export const formatInquiryFormData = (
  formElement: HTMLFormElement,
  data?: GetUsersInquiryResponseInquiry,
  isGuest?: boolean,
) => {
  const requestParams: Record<string, any> = {
    contents: formatStrToJSXString(formElement.contents.value),
  }

  const paramKey = isGuest ? GUEST_PARAM_KEY : USER_PARAM_KEY

  paramKey.forEach((key) => {
    if (isNotNil(formElement[key]) && formElement[key]?.value !== '') {
      if (ARRAY_TYPE_KEY.includes(key)) {
        requestParams[key] = formElement[key].value.split(',')
      } else {
        requestParams[key] = formElement[key].value
      }
    }
  })

  if (requestParams['inquiryType'] !== UserInquiryType['data']) {
    delete requestParams['raId']
    delete requestParams['pnu']
    delete requestParams['inquiryItems']
  }

  const previousFiles = data?.attachments ?? []
  const totalFiles = Array.from(formElement?.file.files ?? []) as File[]

  const newFiles: File[] = []
  const remainFilePaths: string[] = []

  totalFiles.forEach((file) => {
    const alreadyExistFile = previousFiles.find((previousFile) => previousFile.fileName === file.name)
    if (alreadyExistFile && alreadyExistFile.filePath) {
      remainFilePaths.push(alreadyExistFile.filePath)
    } else {
      newFiles.push(file)
    }
  })
  // FIXME: swagger docs 오류로 file 타입이 string타입 으로 변환되어 수정될때까지 @ts-ignore 적용
  // @ts-ignore
  requestParams['attachments'] = newFiles
  requestParams['remainFilePaths'] = remainFilePaths

  return customFormData(requestParams)
}

/**
 * 서버에서 받은 inquiryItem 를 string[] 로 변환해주는 함수
 * - 서버에서 inquiryItem 타입이 restdoc의 오류로  string[] 이 아닌 (boolean | string | number | object)[] 형태로 내려옴.
 * @param inquiryItems
 */
export const getStrInquiryItems = (
  inquiryItems: GetUsersInquiryResponseInquiryInquiryItemsItem[] | undefined | null,
) => {
  if (!inquiryItems || (inquiryItems && inquiryItems.length === 0)) {
    return []
  }

  const filtered: InquiryItems[] = []
  inquiryItems.forEach((item) => {
    if (typeof item === 'string' && Object.keys(InquiryItemType).includes(item)) {
      filtered.push(item as InquiryItems)
    }
  })
  return filtered
}
