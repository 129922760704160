import { FC, useMemo } from 'react'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import { useTranslation } from 'next-i18next'
import useNilableValueAccessor from '~/components/DataGrid/hooks/useNilableValueAccessor'
import DataGrid from '~/components/DataGrid/DataGrid'
import { PagerModel } from '@syncfusion/ej2-grids/src/pager/pager-model'

interface HoGridProps {
  dataSource: Object[] | undefined
  pagerModel?: PagerModel
  onPageChange: (object: { currentPage: number }) => void
}

const HoGrid: FC<HoGridProps> = ({ dataSource, pagerModel, onPageChange }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })

  const columns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'floorGubun',
        headerText: `${t('ground')}/${t('basement')} ${t('division')}`,
        textAlign: 'Left',
        valueAccessor: (_: any, data: any) => data.floorGubun ?? '-',
      },
      {
        field: 'dongName',
        headerText: t('dong_name'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'hoName',
        headerText: t('ho_name'),
        textAlign: 'Left',
        clipMode: 'EllipsisWithTooltip',
        valueAccessor: useNilableValueAccessor,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <DataGrid
      dataSource={dataSource}
      pagerModel={pagerModel}
      columns={columns}
      paginationMode="server"
      onPageChange={onPageChange}
    />
  )
}

export default HoGrid
