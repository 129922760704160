import Box from '~/components/Box/Box'
import FunctionButtons from '~/templates/place/detail/overview/FunctionButtons'
import { ButtonData } from '~/templates/place/detail/overview/OverviewButton'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import Typography from '~/components/Typography/Typography'
import { useDetailScrollState } from '~/templates/DetailScrollProvider'

interface NavBarProps {
  buttonData: ButtonData[]
  name?: string
}

const NavBar = ({ buttonData, name }: NavBarProps) => {
  const { detailType } = useDetailInfo()
  const isPanel = detailType === 'panel'
  const { isTop } = useDetailScrollState()

  return (
    <Box
      position="relative"
      height={56}
      backgroundColor="system-white"
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      width="100%"
      px={4}
      py={2}
    >
      {!isTop && (
        <Box maxWidth={isPanel ? '224px' : '852px'} position="absolute" left="20px">
          <Typography
            variant="h5"
            fontWeight="semibold"
            color="gray-800"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {name}
          </Typography>
        </Box>
      )}
      <FunctionButtons data={buttonData} />
    </Box>
  )
}

export default NavBar
