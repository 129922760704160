import { buildContext } from '@toss/react'
import { FC, PropsWithChildren, useRef, useState } from 'react'
import {
  Inject,
  Magnification,
  Navigation,
  PdfViewerComponent,
  Print,
  TextSearch,
  TextSelection,
  ThumbnailView,
  Toolbar,
} from '@syncfusion/ej2-react-pdfviewer'

import { BeforeOpenEventArgs, DialogComponent } from '@syncfusion/ej2-react-popups'
import styled from '@xstyled/styled-components'
import { EmitType } from '@syncfusion/ej2-base'
import Typography from '~/components/Typography/Typography'
import Box from '~/components/Box/Box'
import { useTranslation } from 'next-i18next'
import IconClose from '~/assets/images/icons/x-graphic.svg'
import { getRegistrationDownload } from '~/libs/apis/registration/api'
import { formatISODateTimeSeconds } from '~/libs/utils/date'
import { GetRegistrationsResponseItem } from '~/libs/apis/registration/model'
import useR3CommonCode from '~/libs/hooks/useR3CommonCode'
import { datadogRum } from '@datadog/browser-rum'
import ErrorDialog from '~/components/Dialog/ErrorDialog'

interface PdfDialogContextProps {
  open: (registration: GetRegistrationsResponseItem) => void
}

const [Context, usePdfDialog] = buildContext<PdfDialogContextProps>('PdfDialogContext', null)

const PdfDialogContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { codeToName } = useR3CommonCode('PROPERTY')
  const ref = useRef<DialogComponent>(null)
  const viewerRef = useRef<PdfViewerComponent>(null)
  const { t } = useTranslation('common', { keyPrefix: 'pdf_viewer' })
  const { t: commonTerm } = useTranslation('common', { keyPrefix: 'common_term' })
  const [isError, setIsError] = useState(false)

  const openDialog = async (registration: GetRegistrationsResponseItem) => {
    ref.current?.show()
    try {
      const downloadInfo = await getRegistrationDownload(registration.registrationId.toString())
      const {
        registrationRealEstateNumber,
        registrationRecordAccessDate,
        registrationPropertyTypeCode,
        registrationAddress,
      } = registration
      const fileName = `${registrationRealEstateNumber}_${formatISODateTimeSeconds(registrationRecordAccessDate)}_${codeToName(registrationPropertyTypeCode)}_${registrationAddress}.pdf`
      if (viewerRef.current) {
        // @ts-ignore password 없이 호출하는 싱크퓨전의 가이드를 따름
        viewerRef.current.load(downloadInfo.downloadUrl, null)
        viewerRef.current.downloadFileName = fileName
      }
    } catch (error) {
      setIsError(true)
      datadogRum.addError(error)
    }
  }

  const handleClickClose = () => {
    ref.current?.hide()
  }

  const handleBeforeOpen: EmitType<BeforeOpenEventArgs> = (args) => {
    args.maxHeight = '100vh'
  }

  const handleClose = () => {
    viewerRef.current?.unload()
  }

  const handlePdfFailed = () => {
    setIsError(true)
  }

  const handleErrorDialogClose = () => {
    setIsError(false)
    ref.current?.hide()
  }

  return (
    <Context open={openDialog}>
      {children}
      <StyledDialog
        ref={ref}
        visible={false}
        width="1200px"
        height="100vh"
        target="#dialog-target"
        position={{ X: 'center', Y: 'top' }}
        beforeOpen={handleBeforeOpen}
        close={handleClose}
        closeOnEscape={false}
        $disableOverlayClose={true}
        isModal
      >
        <Box height="100%" display="flex" flexDirection="column">
          <Box p={5}>
            <Typography variant="h5" fontWeight="bold" color="gray-800">
              {t('title')}
            </Typography>
            <Box position="absolute" top={20} right={20} onClick={handleClickClose} cursor="pointer">
              <IconClose width="16px" height="16px" color="var(--color-gray-700)" />
            </Box>
          </Box>
          <Box flexGrow={1}>
            <PdfViewerComponent
              ref={viewerRef}
              id="pdf-container"
              serviceUrl="https://services.syncfusion.com/react/production/api/pdfviewer"
              height="100%"
              locale="ko"
              ajaxRequestFailed={handlePdfFailed}
              documentLoadFailed={handlePdfFailed}
              toolbarSettings={{
                showTooltip: true,
                toolbarItems: [
                  'PageNavigationTool',
                  'MagnificationTool',
                  'PanTool',
                  'SelectionTool',
                  'SearchOption',
                  'PrintOption',
                  'DownloadOption',
                ],
              }}
            >
              <Inject
                services={[Toolbar, Navigation, Magnification, ThumbnailView, Print, TextSelection, TextSearch]}
              />
            </PdfViewerComponent>
          </Box>
        </Box>
        <ErrorDialog visible={isError} message={commonTerm('error_title')} onClose={handleErrorDialogClose}>
          <Typography variant="subtitle">{commonTerm('error_message')}</Typography>
        </ErrorDialog>
      </StyledDialog>
    </Context>
  )
}

const StyledDialog = styled(DialogComponent)`
  user-select: none;

  &.e-dialog {
    border: 0;
    border-radius: 0;

    .e-dlg-content {
      padding: 0;
    }
  }
`

export { usePdfDialog }

export default PdfDialogContextProvider
