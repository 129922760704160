const systemPalette = {
  'system-white': '#FFFFFF',
  'system-black': '#000000',
}

const eventPalette = {
  'event-info': '#E8FCFF',
  'event-warning': '#FFF3E8',
  'event-success': '#E8FFEE',
  'event-bookmark': '#FFCD1E',
  'event-error': '#E62323',
}

const chartPalette = {
  'chart-1': '#F66567',
  'chart-2': '#FAA364',
  'chart-3': '#FAD760',
  'chart-4': '#B3D46C',
  'chart-5': '#48B4A0',
  'chart-6': '#75C6F4',
  'chart-7': '#64A5E0',
  'chart-8': '#A08DEB',
  'chart-9': '#EF8AC1',
  'chart-10': '#F58D77',
  'chart-11': '#E4C982',
  'chart-12': '#80D197',
  'chart-13': '#7DC5D4',
  'chart-14': '#96ABF8',
  'chart-15': '#6693EB',
  'chart-16': '#C48EE4',
  'chart-17': '#FAA6B5',
  'chart-18': '#97AFD6',
  'chart-19': '#CCAE93',
  'chart-20': '#FAA6B5',
}

const dataPalette = {
  'data-1': '#EFFBFF',
  'data-2': '#FFFBF1',
}

const grayPalette = {
  'gray-100': '#F9FAFB',
  'gray-200': '#F3F4F6',
  'gray-300': '#E5E8ED',
  'gray-400': '#D7DBE2',
  'gray-500': '#C7CCD7',
  'gray-600': '#8F95A1',
  'gray-700': '#616873',
  'gray-800': '#313437',
}

const bluePalette = {
  'blue-100': '#F3F6FF',
  'blue-200': '#EBF0FE',
  'blue-300': '#DAE4FF',
  'blue-400': '#C1D1FB',
  'blue-500': '#9EB5F2',
  'blue-600': '#6486E0',
  'blue-700': '#3B5CB3',
  'blue-800': '#2A458D',
}

const purplePalette = {
  'purple-100': '#F6F4FF',
  'purple-200': '#F1EEFF',
  'purple-300': '#E8E3FF',
  'purple-400': '#D6CEFC',
  'purple-500': '#B6A7F6',
  'purple-600': '#8870ED',
  'purple-700': '#5E44CB',
  'purple-800': '#382194',
}

const greenPalette = {
  'green-100': '#F1FFFE',
  'green-200': '#E9FCFA',
  'green-300': '#D7F7F3',
  'green-400': '#BCEEE8',
  'green-500': '#8FE4D9',
  'green-600': '#31C4B2',
  'green-700': '#098D7D',
  'green-800': '#06625C',
}

const colors = {
  ...systemPalette,
  ...eventPalette,
  ...chartPalette,
  ...dataPalette,
  ...grayPalette,
  ...bluePalette,
  ...purplePalette,
  ...greenPalette,
}

export type ColorPalette = typeof colors

export default colors
