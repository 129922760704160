import { FC, PropsWithChildren } from 'react'
import { MintMapProps } from '@mint-ui/map/dist/components/mint-map/types/MintMapProps'
import { MintMap, MintMapController, Position } from '@mint-ui/map'
import { MapVendorType } from '@mint-ui/map/dist/components/mint-map/types/CommonTypes'
import { CONTENTS_NAVER_MAP_OPTIONS } from '~/libs/constants/place'
import Box from '../Box/Box'
import ZoomControl from '~/templates/common/map/control/ZoomControl'
import { MapControlWrapper } from '@mint-ui/map/dist/components/mint-map/core/wrapper/MapControlWrapper'
import { isNonEmptyArray } from '@toss/utils'
import { COMMON_NAVER_MAP_OPTIONS, NMapZoomLevel } from '~/libs/constants/common'

interface ContentMintMapProps extends Omit<MintMapProps, 'mapType' | 'mapKey' | 'dissolveEffectWhenLoaded'> {
  focusBoundary?: Position[]
}

const ContentMintMap: FC<PropsWithChildren<ContentMintMapProps>> = ({
  children,
  onLoad,
  focusBoundary,
  scriptModules,
  ...props
}) => {
  const handleLoad = (_: MapVendorType, controller: MintMapController) => {
    onLoad?.(_, controller)
    const map = controller.map
    if (map instanceof naver.maps.Map) {
      map.setOptions({ ...CONTENTS_NAVER_MAP_OPTIONS, ...COMMON_NAVER_MAP_OPTIONS })
    }

    if (focusBoundary && isNonEmptyArray(focusBoundary)) {
      controller.focusPositionsToFitViewport(focusBoundary)
    }
  }

  return (
    <MintMap
      mapType="naver"
      mapKey={process.env.NAVER_API_KEY || ''}
      onLoad={handleLoad}
      dissolveEffectWhenLoaded={false}
      scriptModules={['panorama']}
      {...props}
    >
      {children}
      <MapControlWrapper positionHorizontal="right" positionVertical="bottom">
        <Box display="flex" flexDirection="column" alignItems="flex-end" gap="16px" margin="0 20px 34px 0">
          <ZoomControl maxZoomLevel={NMapZoomLevel['5m']} />
        </Box>
      </MapControlWrapper>
    </MintMap>
  )
}

export default ContentMintMap
