import { FC, useEffect, useRef, useState } from 'react'
import styled from '@xstyled/styled-components'
import { UploaderComponent } from '@syncfusion/ej2-react-inputs'
import { useTranslation } from 'react-i18next'
import { EmitType } from '@syncfusion/ej2-base'
import { SelectedEventArgs } from '@syncfusion/ej2-inputs/src/uploader/uploader'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import IconClose from '~/assets/images/icons/circle-close-solid.svg'
import { GetUsersInquiryResponseInquiry } from '~/libs/apis/service/model'
import { useToast } from '~/templates/ToastContextProvider'
import FilterSelectButton from '~/components/Button/FilterSelectButton'

const EXTENSIONS = '.hwp, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .csv, .jpg, .jpeg, .gif, .png, .bmp, .pdf'
const FILE_MAX_COUNT = 5
const FILE_MAX_SIZE = Math.pow(1024, 2) * FILE_MAX_COUNT

const hasDuplicateFileName = (fileName: string, fileList: File[]) =>
  fileList.findIndex((file) => file.name === fileName) > -1
const isExceedFileSize = (size: number, file: File) => size < file.size

const dataTransferController = () => {
  const store = new DataTransfer()

  return {
    addFile: (file: File) => store.items.add(file),
    getAllFileInStore: () => store.files,
    getAllFileArray: () => Array.from(store.files),
  }
}

interface InquiryFileFieldProps {
  data?: GetUsersInquiryResponseInquiry
}
const InquiryFileField: FC<InquiryFileFieldProps> = ({ data }) => {
  const uploaderRef = useRef<UploaderComponent | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation('common', { keyPrefix: 'data_inquiry' })
  const { show } = useToast()

  const [files, setFiles] = useState<File[]>([])

  useEffect(() => {
    const isExistAlreadyFiles = data?.attachments && data?.attachments.length > 0
    if (isExistAlreadyFiles) {
      const controller = dataTransferController()
      data.attachments.forEach((item) => {
        if (item) {
          const file = new File([item as BlobPart], item.fileName!)
          controller.addFile(file)
        }
      })

      const input = inputRef.current as HTMLInputElement
      input.files = controller.getAllFileInStore()
      setFiles(controller.getAllFileArray())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSelected: EmitType<SelectedEventArgs> = (arg) => {
    if (files.length > FILE_MAX_COUNT) {
      return
    }
    if (arg.filesData.length === 0) {
      return
    }

    const uploaderElement = uploaderRef.current?.element as HTMLInputElement
    uploaderElement.value = ''

    const newRawFile = arg.filesData[0].rawFile

    if (isExceedFileSize(FILE_MAX_SIZE, newRawFile)) {
      show(t('only_files_smaller_can_be_attached'))
      arg.cancel = true
      return
    }

    if (hasDuplicateFileName(newRawFile.name, files)) {
      show(t('already_attached'))
      arg.cancel = true
      return
    }

    const controller = dataTransferController()
    files.forEach((file) => controller.addFile(file))
    controller.addFile(newRawFile)

    const input = inputRef.current as HTMLInputElement
    input.files = controller.getAllFileInStore()

    setFiles(controller.getAllFileArray())
  }
  const handleRemove = (removeIndex: number) => {
    const controller = dataTransferController()

    const filteredFiles = files.filter((_, index) => index !== removeIndex)
    filteredFiles.forEach((file) => controller.addFile(file))
    setFiles(filteredFiles)

    const input = inputRef.current as HTMLInputElement
    input.files = controller.getAllFileInStore()
  }

  return (
    <Box width="100%" display="flex" gap="10px" flexDirection="column">
      <Box display="flex" justifyContent="space-between" flex="1" alignItems="center">
        <Box display="flex" gap="10px" alignItems="center">
          <Typography fontWeight={600}>{t('attachment')}</Typography>
          <Typography variant="caption1" color="gray-600">
            {t('attachment_tooltip')}
          </Typography>
        </Box>
        <StyledWrapper className="form-group">
          <UploaderComponent
            ref={uploaderRef}
            id="data-inquiry-file"
            type="file"
            cssClass="style-guide"
            buttons={{
              browse: t('add_file'),
            }}
            dropArea=""
            multiple={false}
            showFileList={false}
            enabled={files.length < FILE_MAX_COUNT}
            selected={onSelected}
            allowedExtensions={EXTENSIONS}
          />
          <input ref={inputRef} name="file" type="file" hidden />
        </StyledWrapper>
      </Box>
      {files.length > 0 && (
        <Box display="flex" gap="6px" flex="1" flexWrap="wrap">
          {files.map((file, index) => (
            <FilterSelectButton
              key={file.name}
              width="fit-content"
              type="button"
              content={
                <Box display="flex" maxWidth="503px">
                  <Typography
                    variant="caption1"
                    color="gray-800"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                  >
                    {file.name}
                  </Typography>
                </Box>
              }
              icon={
                <Box
                  cursor="pointer"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleRemove(index)
                  }}
                >
                  <IconClose width="12px" height="12px" color="var(--color-gray-600)" />
                </Box>
              }
              iconPosition="right"
            />
          ))}
        </Box>
      )}
    </Box>
  )
}

const StyledWrapper = styled.div`
  & .style-guide.e-upload {
    border: none;
    display: flex;

    & > .e-file-select-wrap {
      padding: 0;
      height: 36px;
    }

    & > .e-file-select-wrap > button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      padding: 4px 10px;
      background-color: var(--color-gray-200);
      color: var(--color-gray-700);
      border-color: var(--color-gray-200);
      font-weight: 600;
      box-shadow: none !important;
      font-size: 12px;

      &.e-active,
      &:active:not(:disabled) {
        background-color: var(--color-gray-700);
        color: var(--color-system-white);
        border-color: var(--color-gray-700);
      }
    }

    & > .e-file-select-wrap > button[disabled='disabled'] {
      background: var(--color-gray-200);
      color: var(--color-gray-500);
      cursor: default;
      pointer-events: none;
    }
  }
`

export default InquiryFileField
