/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.3.1
 */

export type ReportStatusType = typeof ReportStatusType[keyof typeof ReportStatusType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportStatusType = {
  success: 'success',
  fail: 'fail',
  processing: 'processing',
  queued: 'queued',
} as const;
