import React, { FC, useEffect, useRef } from 'react'
import { useGetNotices } from '~/libs/apis/service/api'
import { Box } from '~/components'
import Button from '~/components/Button/Button'
import styled from '@xstyled/styled-components'
import { DialogComponent } from '@syncfusion/ej2-react-popups'
import { commonDialogCss } from '~/components/Dialog/dialogStyle'
import { useTranslation } from 'next-i18next'
import { useBooleanState } from '@toss/react'
import { NOTICE_SKIP_ID_KEY, SWR_IMMUTABLE_OPTION } from '~/libs/constants/common'
import { CarouselComponent } from '@syncfusion/ej2-react-navigations'
import BuildingImage from '~/components/Image/BuildingImage'
import AngleLeft from '~/assets/images/icons/angle-left.svg'
import AngleRight from '~/assets/images/icons/angle-right.svg'
import { GetNoticesResponseItemNoticeImagesItem } from '~/libs/apis/service/model'

const sortImageOrder = (a: GetNoticesResponseItemNoticeImagesItem, b: GetNoticesResponseItemNoticeImagesItem) =>
  (a.order ?? 0) - (b.order ?? 0)

const NoticeDialog: FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const [isDialogVisibled, renderCarousel] = useBooleanState(false)
  const carouselRef = useRef<CarouselComponent>(null)
  const dialogRef = useRef<DialogComponent>(null)
  const { data } = useGetNotices(SWR_IMMUTABLE_OPTION)

  const notice = data?.filter((notice) => notice.noticeType === 'IMAGE').at(0)

  const handleClickSkipButton = () => {
    if (notice) {
      localStorage.setItem(NOTICE_SKIP_ID_KEY, notice.noticeId.toString())
    }
    dialogRef.current?.hide()
  }

  const handleClickCloseButton = () => {
    dialogRef.current?.hide()
  }

  const previousButtonTemplate = () => (
    <Box
      width="22px"
      height="22px"
      position="absolute"
      top="16px"
      right="44px"
      backgroundColor="gray-800"
      borderRadius="12px"
      color="system-white"
      cursor="pointer"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <AngleLeft width={8} height={8} />
    </Box>
  )

  const nextButtonTemplate = () => (
    <Box
      width="22px"
      height="22px"
      position="absolute"
      top="16px"
      right="16px"
      backgroundColor="gray-800"
      borderRadius="12px"
      color="system-white"
      cursor="pointer"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <AngleRight width={8} height={8} />
    </Box>
  )

  const footerTemplate = () => (
    <Box display="flex" justifyContent="center" gap={4}>
      <Button
        size="lg"
        width="132px"
        variant="line"
        color="gray"
        content={t('skip_dialog')}
        onClick={handleClickSkipButton}
      />
      <Button size="lg" width="138px" content={t('close')} onClick={handleClickCloseButton} />
    </Box>
  )

  useEffect(() => {
    if (notice && notice.noticeId.toString() !== localStorage.getItem(NOTICE_SKIP_ID_KEY)) {
      dialogRef.current?.show()
    }
  }, [notice])

  const handleOpen = () => {
    renderCarousel()
  }

  const itemTemplate = (image: GetNoticesResponseItemNoticeImagesItem) => {
    return (
      <Box
        cursor={image.link ? 'pointer' : 'normal'}
        onClick={() => {
          if (image.link) {
            window.open(image.link, '_blank', 'noopener')
          }
        }}
      >
        <BuildingImage url={image.imageUrl} width={400} height={440} />
      </Box>
    )
  }

  const dataSource = notice?.noticeImages?.toSorted(sortImageOrder)

  return (
    <StyledDialog
      ref={dialogRef}
      target="#dialog-target"
      visible={false}
      footerTemplate={footerTemplate}
      isModal
      open={handleOpen}
    >
      <Box width="400px" height="440px" className="control-container">
        {isDialogVisibled && (
          <StyledCarousel
            ref={carouselRef}
            selectedIndex={0}
            indicatorsType="Fraction"
            previousButtonTemplate={previousButtonTemplate}
            nextButtonTemplate={nextButtonTemplate}
            dataSource={dataSource}
            itemTemplate={itemTemplate}
          ></StyledCarousel>
        )}
      </Box>
    </StyledDialog>
  )
}

const StyledDialog = styled(DialogComponent)`
  ${commonDialogCss}
  & {
    overflow: hidden;

    .e-dlg-content {
      padding: 0;
      background-color: var(--system-white);
    }
  }
`

const StyledCarousel = styled(CarouselComponent)`
  .e-fraction {
    top: 16px;
    bottom: unset;
    right: 72px;
    justify-content: flex-end;
    min-height: 22px !important;

    .e-indicator-bars {
      padding: 2px 10px;
      background-color: var(--color-gray-800);
      border-radius: 12px;
      font-weight: bold;
      font-size: 12px;
    }
  }
`

export default NoticeDialog
