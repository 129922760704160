import FullScreenModal from '~/components/Dialog/FullScreenModal'
import { BaseProps, MintMap, MintMapController, PolygonMarker, Position } from '@mint-ui/map'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import CurrentBuildingMarkerIcon from '~/assets/images/marker/current-building.svg'
import { styled, useTheme } from '@xstyled/styled-components'
import CurrentBuildingMarker from '~/components/Map/CurrentBuildingMarker'
import { MapVendorType } from '@mint-ui/map/dist/components/mint-map/types/CommonTypes'
import { OfficeOutBuildingBoundary } from '~/libs/apis/data/model'
import { geomJsonToMultiPositions } from '~/libs/utils/map'
import { CONTENTS_NAVER_MAP_OPTIONS } from '~/libs/constants/place'

interface StreetViewModalProps {
  center: Position
  boundary: OfficeOutBuildingBoundary
  onClose: () => void
}

const StreetViewModal = ({ center, boundary, onClose }: StreetViewModalProps) => {
  const theme = useTheme()
  const miniMapRef = useRef<MintMapController>()
  const panoramaRef = useRef<naver.maps.Panorama>()
  const [miniMapMarkerPosition, setMiniMapMarkerPosition] = useState<Position>()

  const initPanorama = () => {
    if (!naver.maps) {
      return
    }

    const panorama = new naver.maps.Panorama('street-view', {
      position: center,
      logoControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: naver.maps.Position.BOTTOM_RIGHT,
        style: naver.maps.ZoomControlStyle.SMALL,
      },
      pov: {
        pan: 5,
        tilt: 0,
        fov: 100,
      },
    })

    panoramaRef.current = panorama

    const currentOfficeMarker = new naver.maps.Marker({
      position: center,
      icon: {
        content: ReactDOMServer.renderToString(<CurrentBuildingMarkerIcon width={32} height={40} />),
        size: new naver.maps.Size(32, 40),
      },
    })

    naver.maps.Event.once(panorama, 'init', () => {
      currentOfficeMarker.setMap(panorama as unknown as naver.maps.Map)
      const projection = panorama.getProjection()
      const customPov = projection.fromCoordToPov(currentOfficeMarker.getPosition() as naver.maps.LatLng)

      if (customPov) {
        panorama.setPov(customPov)
      }

      naver.maps.Event.addListener(panorama, 'pano_changed', function () {
        const positionAsPosition = new Position(panorama.getPosition().lat(), panorama.getPosition().lng())
        if (miniMapRef.current && !Position.equals(positionAsPosition, miniMapRef.current.getCenter())) {
          miniMapRef.current.setCenter(positionAsPosition)
        }
      })
    })
  }

  const miniMapBase: BaseProps = {
    center: center,
    zoomLevel: 16,
    minZoomLevel: 15,
    maxZoomLevel: 20,
  }

  const handleMiniMapLoad = (map: MapVendorType, controller: MintMapController) => {
    miniMapRef.current = controller
    setMiniMapMarkerPosition(center)

    if (map instanceof naver.maps.Map) {
      map.setOptions(CONTENTS_NAVER_MAP_OPTIONS)

      const streetLayer = new naver.maps.StreetLayer()
      streetLayer.setMap(map)
    }

    controller.addEventListener('DBLCLICK', () => {
      controller.setCenter(center)
    })
  }

  const handleMiniMapClick = (position: Position) => {
    panoramaRef.current?.setPosition(position)
    setMiniMapMarkerPosition(position)
  }

  useEffect(() => {
    initPanorama()
  }, [])

  return (
    <FullScreenModal onClose={onClose}>
      <StreetViewContainer id="street-view" />
      <MiniMapContainer>
        <MintMap
          base={miniMapBase}
          mapType="naver"
          mapKey={process.env.NAVER_API_KEY || ''}
          onLoad={handleMiniMapLoad}
          onClick={handleMiniMapClick}
        >
          {boundary &&
            geomJsonToMultiPositions(boundary).map((polygon, polygonIndex) => (
              <PolygonMarker
                key={`${polygonIndex}_${polygon[0].lat}_${polygon[0].lng}`}
                position={polygon}
                shapeProperties={{
                  fill: theme.colors['purple-700'],
                  fillOpacity: 0.3,
                  stroke: theme.colors['purple-700'],
                  pointerEvents: 'none',
                }}
              />
            ))}
          <CurrentBuildingMarker position={miniMapMarkerPosition ?? center} width={16} height={20} zIndex={1} />
        </MintMap>
      </MiniMapContainer>
    </FullScreenModal>
  )
}

const StreetViewContainer = styled.div`
  width: 100%;
  height: 100%;
  transform: translateZ(0);

  cursor: grab;

  :active {
    cursor: grabbing;
  }
`

const MiniMapContainer = styled.div`
  position: absolute !important;
  bottom: 0;
  left: 0;
  width: 250px;
  height: 150px;
  border-radius: 4px;
`

export default StreetViewModal
