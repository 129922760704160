import { MouseEvent, forwardRef, useLayoutEffect, useRef, useState } from 'react'
import { CSSProperties, css, styled } from '@xstyled/styled-components'
import Box from '~/components/Box/Box'

import { Option } from '~/components/Dropdown/ListSelect'
import { ChangeEventArgs, DropDownListComponent, DropDownListModel } from '@syncfusion/ej2-react-dropdowns'
import classNames from 'classnames'
import Typography from '~/components/Typography/Typography'

import angleDown from '~/assets/images/icons/angle-down.svg?url'
import CircleCloseSolid from '~/assets/images/icons/circle-close-solid.svg'
import { scrollCss } from '~/components/Dropdown/Popper'

interface DropdownProps {
  /** 선택가능한 항목 배열 `label`: 표시될 텍스트, `value`: 항목 식별자 */
  options?: Option[]
  /** 선택된 항목의 `value` 식별자 값 */
  value?: string
  /** 항목 선택시 발생되는 이벤트 핸들러, 선택된 항목의 `value` 식별자 값이 첫번째 인자, `option` 정보가 두번째 인자 */
  onChange?: (value?: string, option?: Option) => void
  /** `Dropdown` 값이 선택되어있지 않을때 안내 문구 */
  placeholder?: string
  /** `Dropdown`의 `width` 값 */
  width?: CSSProperties['width']
  /** `Dropdown` 클릭시 출력되는 popup 메뉴의 `width` 값 */
  popperWidth?: CSSProperties['width']
  /** `Dropdown` 클릭시 출력되는 popup 메뉴의 `height` 값, 콘텐츠가 해당 값을 넘어가면 `overflow: auto`로 처리됨 */
  popperHeight?: CSSProperties['height']
  /** `Dropdown` 값 선택시 해당 단위 문자열이 값 뒤에 여백을 두고 표시됨 */
  unit?: string
  /** 값 지우기 버튼 출력 여부 (컴포넌트 초기화시 값으로만 적용됨), 값이 선택되었을때만 출력됨 */
  showClearButton?: boolean
  /** `Dropdown`의 읽기 모드 여부 */
  readOnly?: boolean
  /** `Dropdown`의 비활성화 여부 */
  disabled?: boolean
}

/** 단일 선택 `Dropdown` 컴포넌트 */
const Dropdown = ({
  placeholder,
  value,
  unit,
  width,
  popperWidth,
  popperHeight = '300px',
  onChange,
  options = [],
  showClearButton: rootShowClearButton,
  readOnly,
  disabled,
}: DropdownProps) => {
  const [showClearButton] = useState(rootShowClearButton)
  const ref = useRef<DropDownListComponent | null>(null)

  const handleChange = (e: ChangeEventArgs) => {
    onChange?.(e.value?.toString(), e.itemData as Option)
  }

  const handleMouseDown = (e: MouseEvent) => {
    e.stopPropagation()
  }

  const handleClear = (e: MouseEvent) => {
    e.stopPropagation()
    ref.current?.clear()
  }

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.value = (value || null) as string
      ref.current.dataBind()
    }
  }, [value])

  return (
    <StyledDropDownListComponent
      ref={ref}
      readonly={readOnly}
      enabled={!disabled}
      className={classNames('prevent-popper-close', { 'show-clear-button': showClearButton })}
      width={width}
      popupWidth={popperWidth || width}
      popupHeight={popperHeight}
      dataSource={options as Array<{}>}
      placeholder={placeholder}
      valueTemplate={({ label }: Option) => (
        <Box
          display="flex"
          alignItems="center"
          height="34px"
          pl={2}
          borderRight={showClearButton && '1px solid'}
          borderRightColor="gray-400"
        >
          <ValueLabel>{label}</ValueLabel>
          {unit && (
            <Typography className="unit" color="gray-800">
              {unit}
            </Typography>
          )}
          {showClearButton && (
            <Box color="gray-800" mx={2}>
              <CircleCloseSolid onMouseDown={handleMouseDown} onClick={handleClear} width="12px" height="12px" />
            </Box>
          )}
        </Box>
      )}
      value={value}
      fields={{ text: 'label', value: 'value' }}
      change={handleChange}
    />
  )
}

export default Dropdown

const ValueLabel = styled(Box)`
  flex: 1;
  font-size: body;
  line-height: body;
  color: inherit;
`

export const DropDownListComponentBase = forwardRef<DropDownListComponent, DropDownListModel & { className?: string }>(
  ({ className, ...props }, ref) => <DropDownListComponent ref={ref} cssClass={className} {...props} />,
)

DropDownListComponentBase.displayName = 'DropDownListComponentBase'

const itemCss = css`
  border: none;
  border-radius: 4px;
  padding: 12px 8px;
  font-size: body;
  line-height: body;
  color: gray-800;
  height: auto;
  box-shadow: none;
`

const itemActiveCss = css`
  background-color: gray-200;
  color: gray-800;
  font-weight: semibold;
`

export const dropdownBaseCss = css`
  border-radius: 8px;
  border-color: var(--color-gray-400);
  font-weight: semibold;

  &.e-disabled:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) {
    background: var(--color-gray-200);
    .e-ddl-icon::before {
      background: var(--color-gray-500);
    }
    &,
    input.e-input,
    input.e-input::placeholder,
    input.e-dropdownbase,
    input.e-dropdownbase::placeholder,
    .unit {
      -webkit-text-fill-color: var(--color-gray-500);
      color: var(--color-gray-500);
    }
  }
  &.e-readonly {
    background-color: var(--color-gray-200);
    cursor: unset;

    input.e-input,
    .unit {
      background: none;
    }

    &,
    input.e-input {
      color: var(--color-gray-700);
      -webkit-text-fill-color: var(--color-gray-700);
    }
  }

  input.e-input,
  input.e-input::placeholder,
  input.e-dropdownbase,
  input.e-dropdownbase::placeholder {
    height: 34px;
    font-size: body;
    line-height: body;
    font-weight: regular;
    color: var(--color-gray-500);
    background: none;
    padding-left: 8px;
  }
  .e-input-value,
  .e-delim-values {
    font-size: body;
    line-height: 34px;
    color: var(--color-gray-800);
    padding-left: 8px;
  }
  .e-delim-values {
    padding-left: 2;
  }
  &.e-input-focus,
  &:active {
    box-shadow: none !important;
    outline: none !important;
    border-color: var(--color-gray-400) !important;
    transition: border-color 0.15s ease-in-out !important;
    .e-input-value,
    .e-delim-values {
      color: var(--color-gray-800) !important;
      -webkit-text-fill-color: var(--color-gray-800);
      font-weight: semibold;
    }
  }
`

export const dropdownIconCss = css`
  &.e-icon-anim .e-ddl-icon {
    transform: rotate(-180deg);
  }
  .e-ddl-icon {
    position: absolute;
    right: 0;
    width: 12px;
    min-width: 12px;
    height: 34px;
    margin-left: 8px;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
    transform: rotate(-0deg);
    transition: transform 300ms ease;
    border: none;
    background-color: transparent;

    &::before {
      content: '';
      width: 12px;
      height: 12px;
      background-color: var(--color-gray-800);
      mask-image: url('${angleDown}');
      mask-size: contain;
      mask-position: center;
    }
    &:hover {
      background: none;
    }
    &:active {
      box-shadow: none;
    }
  }
  &.show-clear-button .e-ddl-icon {
    margin-left: 2;
  }

  &.e-disabled {
    .e-ddl-icon {
      &::before {
        background-color: var(--color-gray-500);
      }
    }
  }

  &.e-readonly {
    .e-ddl-icon {
      &::before {
        background-color: transparent;
      }
    }
  }
`

export const dropdownListCss = css`
  border-radius: 8px;
  box-shadow: black-03;
  border-color: var(--color-gray-400);

  .e-dropdownbase {
    padding: 8px 0 8px 8px;
    ${scrollCss}

    .e-list-parent {
      display: flex;
      flex-direction: column;
      gap: 4px;
      border: none;
    }

    .e-list-item,
    .e-list-item.e-item-focus {
      ${itemCss}
      text-indent: initial;
      border-radius: 8px;
      padding: 8px;
    }

    .e-list-item.e-item-focus,
    .e-list-item.e-active,
    .e-list-item.e-active.e-hover {
      ${itemActiveCss}
    }
  }
`

const StyledDropDownListComponent = styled(DropDownListComponentBase)`
  * {
    font-family: pretendard;
  }
  &.e-input-group.e-control-wrapper,
  &.e-input-group.e-control-wrapper.e-ddl.e-lib {
    ${dropdownBaseCss}
    ${dropdownIconCss}
  }

  &.e-popup.e-ddl {
    box-sizing: border-box;
    margin-top: 8px;
    ${dropdownListCss}
  }
`
