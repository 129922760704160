import buildingFilterStateFamily from '~/recoil/building-filter/atom'
import landFilterStateFamily from '~/recoil/land-filter/atom'
import tenantFilterStateFamily from '~/recoil/topic-tenant-filter/atom'
import { useNextRouter } from '~/libs/hooks/useNextRouter'
import { PageRoutes } from '~/libs/constants/permission'
import isBuildingMode from '~/recoil/data-type/isBuildingMode'
import { useRecoilValue } from 'recoil'
import registrationFilterStateFamily from '~/recoil/topic-registration-filter/atom'

type FilterFamilies = {
  [key in FilterType]:
    | typeof buildingFilterStateFamily
    | typeof landFilterStateFamily
    | typeof tenantFilterStateFamily
    | typeof registrationFilterStateFamily
}

interface MapTypes {
  mapType: MapType // 탐색에서 쓰이는 지도들을 결정짓는 값. route에 따라 결정됨
  filterType: FilterType // 지도별로 사용되는 필터들을 결정짓는 값. mapType과 isBuilding flag에 의해 결정됨
  filterStateFamily:
    | typeof buildingFilterStateFamily
    | typeof landFilterStateFamily
    | typeof tenantFilterStateFamily
    | typeof registrationFilterStateFamily
}

const getFilterType = (mapType: MapType, isBuilding: boolean): FilterType => {
  if (mapType !== 'place') {
    return mapType
  }

  return isBuilding ? 'building' : 'land'
}

const mapTypes: Record<(typeof PageRoutes)[keyof typeof PageRoutes], MapType> = {
  [PageRoutes.place]: 'place',
  [PageRoutes.topicTenant]: 'tenant',
  [PageRoutes.topicRegistration]: 'registration',
}

/**
 * 지도 탐색시 현재 상태를 구분지을수 있게 도와주는 hook
 * @return {MapTypes}
 */
const useMapType = (): MapTypes => {
  const router = useNextRouter()
  const mapType = mapTypes[router.pathname] ?? 'place'
  const isBuilding = useRecoilValue(isBuildingMode)
  const filterType = getFilterType(mapType, isBuilding)

  const filterFamilies: FilterFamilies = {
    building: buildingFilterStateFamily,
    land: landFilterStateFamily,
    tenant: tenantFilterStateFamily,
    registration: registrationFilterStateFamily,
  }

  return { mapType, filterType, filterStateFamily: filterFamilies[filterType] }
}

export default useMapType
