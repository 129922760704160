import React, { FC, ReactElement, useEffect, useRef } from 'react'
import { styled } from '@xstyled/styled-components'
import { DialogComponent } from '@syncfusion/ej2-react-popups'
import IconXGraphic from '~/assets/images/icons/x-graphic.svg'
import { EmitType } from '@syncfusion/ej2-base'
import { BeforeOpenEventArgs } from '@syncfusion/ej2-popups/src/dialog/dialog'
import { useTranslation } from 'next-i18next'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import Guide from '~/assets/images/guide.svg'
import AngleRight from '~/assets/images/icons/angle-right.svg'
import Talk from '~/assets/images/icons/talk.svg'
import Question from '~/assets/images/icons/circle-question-light.svg'
import Place from '~/assets/images/more/place.svg'
import Land from '~/assets/images/more/land.svg'
import Analysis from '~/assets/images/more/analysis.svg'
import Similar from '~/assets/images/more/similar.svg'
import Report from '~/assets/images/more/report.svg'
import Registration from '~/assets/images/more/registration.svg'
import { useNextRouter } from '~/libs/hooks/useNextRouter'
import FooterLinks from '~/templates/common/content/FooterLinks'
import { MAP_LAYER_Z_INDEX } from '~/libs/constants/common'

interface CardText {
  title: string
  body: string
}

interface CardInfo {
  image: ReactElement
  url: string
}

const cardInfos: Array<CardInfo> = [
  {
    image: <Place key="place" width={298} height={110} />,
    url: 'https://www.notion.so/rsquareon/1-c2e2f02e022144bc9fb35bcd37587be4',
  },
  {
    image: <Land key="land" width={298} height={110} />,
    url: 'https://www.notion.so/rsquareon/2-d2cbc282dab7421faf26b348af61ebac',
  },
  {
    image: <Analysis key="analysis" width={298} height={110} />,
    url: 'https://www.notion.so/rsquareon/3-5cec3bf607f844559d9a66a5d9be3a90',
  },
  {
    image: <Similar key="similar" width={298} height={110} />,
    url: 'https://www.notion.so/rsquareon/4-b9b4fbb1cbe64fd2b079fae9ca09cc7c',
  },
  {
    image: <Report key="report" width={298} height={110} />,
    url: 'https://www.notion.so/rsquareon/5-9f076933caf946ae8d672410f3026c58',
  },
  {
    image: <Registration key="registration" width={298} height={110} />,
    url: 'https://www.notion.so/rsquareon/6-4e36d8bb4acf478ebce6222677399caf',
  },
] as const

interface MoreInfoDialogProps {
  open: boolean
  onClose: () => void
}

const MoreInfoDialog: FC<MoreInfoDialogProps> = ({ open, onClose }) => {
  const ref = useRef<DialogComponent | null>(null)
  const { t } = useTranslation('common', { keyPrefix: 'more_info' })
  const { t: common } = useTranslation('common', { keyPrefix: 'detail' })
  const cards: CardText[] = t('card', { returnObjects: true })
  const router = useNextRouter()

  const handleBeforeOpen: EmitType<BeforeOpenEventArgs> = (args) => {
    args.maxHeight = '100vh'
  }
  const handleClickInquiry = () => {
    return router.push('/my-page/?selectedTab=inquiry')
  }
  const createWindowOpenHandler = (url: string) => () => {
    window.open(url, '_blank', 'noopener')
  }

  useEffect(() => {
    const handleResize = () => {
      if (ref?.current) {
        ref.current.refreshPosition()
      }
    }

    window.addEventListener('resize', handleResize)
    router.events.on('routeChangeStart', onClose)

    return () => {
      window.removeEventListener('resize', handleResize)
      router.events.off('routeChangeStart', onClose)
    }
  }, [onClose, ref, router])

  return (
    <StyledDialog
      ref={ref}
      visible={open}
      beforeOpen={handleBeforeOpen}
      target="#dialog-target"
      position={{ X: 'right', Y: 60 }}
      showCloseIcon={false}
      zIndex={MAP_LAYER_Z_INDEX.MORE_INFO}
      width="346px"
      height="calc(100vh - 60px)"
      animationSettings={{ effect: 'SlideRight', duration: 400, delay: 0 }}
    >
      <Box height="100%" display="flex" flexDirection="column">
        <Box
          p={6}
          gap={6}
          backgroundColor="system-white"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" fontWeight="semibold">
            {t('title')}
          </Typography>
          <Box onClick={onClose} cursor="pointer">
            <IconXGraphic width={14} height={14} />
          </Box>
        </Box>
        <Box flexGrow={1} overflowY="auto" tabIndex={0} outline="none">
          <Box padding="0 24px 24px 24px" display="flex" flexDirection="column" gap={6} backgroundColor="system-white">
            <Box
              width="298px"
              height="100px"
              backgroundColor="purple-100"
              borderRadius="10px"
              display="flex"
              py="26px"
              paddingLeft="30px"
              gap={5}
              cursor="pointer"
              onClick={createWindowOpenHandler('https://rsquareon.notion.site/27008e852fa44d2f83c5448261961279')}
            >
              <Guide width={50} height={42} />
              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Typography variant="h5" fontWeight="semibold" color="purple-700">
                  {t('guide_header')}
                </Typography>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography variant="body" color="gray-800">
                    {t('guide_link')}
                  </Typography>
                  <AngleRight width={10} height={10} />
                </Box>
              </Box>
            </Box>
            <Box display="flex" gap={3} height="52px">
              <Box
                flexGrow={1}
                display="flex"
                gap={2}
                justifyContent="center"
                alignItems="center"
                backgroundColor="gray-200"
                borderRadius="10px"
                cursor="pointer"
                onClick={handleClickInquiry}
              >
                <Talk width={16} height={16} />
                <Typography variant="body" fontWeight="semibold">
                  {t('inquiry')}
                </Typography>
              </Box>
              <Box
                flexGrow={1}
                display="flex"
                gap={2}
                justifyContent="center"
                alignItems="center"
                backgroundColor="gray-200"
                borderRadius="10px"
                cursor="pointer"
                onClick={createWindowOpenHandler('https://rsquareon.notion.site/FAQ-fa06ac79b5bd43749927b6bd50cd6510')}
              >
                <Question width={16} height={16} />
                <Typography variant="body" fontWeight="semibold">
                  {t('faq')}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box p={6} backgroundColor="gray-100">
            <Typography variant="body" fontWeight="semibold">
              {t('menu_guide')}
            </Typography>
            <Box display="flex" flexDirection="column" gap={6} mt={4} mb={4}>
              {cards.map((card, index) => (
                <Box
                  key={index}
                  borderRadius="12px"
                  width="298px"
                  overflow="hidden"
                  backgroundColor="system-white"
                  boxShadow="black-02"
                  cursor="pointer"
                  onClick={createWindowOpenHandler(cardInfos[index].url)}
                >
                  {cardInfos[index].image}
                  <Box p={4}>
                    <Typography variant="body" fontWeight="bold">
                      {card.title}
                    </Typography>
                    <Typography variant="caption1" color="gray-700" whiteSpace="pre-wrap">
                      {card.body}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            height="162px"
            backgroundColor="gray-200"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap="10px"
          >
            <FooterLinks showInquireUse={false} />
            <Typography variant="caption1" color="gray-600">
              {common('footer')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </StyledDialog>
  )
}

const StyledDialog = styled(DialogComponent)`
  overflow: hidden;
  box-shadow: var(--shadow-black-06);
  border: none;

  .e-dlg-content {
    padding: 0;
    overflow: hidden;
  }
`

export default MoreInfoDialog
