/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.3.1
 */

export type OfficeMarketTradeMetricType = typeof OfficeMarketTradeMetricType[keyof typeof OfficeMarketTradeMetricType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfficeMarketTradeMetricType = {
  tradeSize: 'tradeSize',
  pricePerM2: 'pricePerM2',
  capRate: 'capRate',
} as const;
