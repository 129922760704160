import { FC } from 'react'
import { useBuildingsDataV1BuildingsGet } from '~/libs/apis/data/buildings/buildings'
import Button from '~/components/Button/Button'
import IconMarkerBuilding from '~/assets/images/icons/marker-building.svg'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import { AreaLandOut, type BuildingsOut } from '~/libs/apis/data/model'
import { useTranslation } from 'react-i18next'
import { useBooleanState } from '@toss/react'
import BuildingInformationDialog from '~/templates/place/detail/overview/BuildingInformationDialog'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import Box from '~/components/Box/Box'

const BuildingInformationButton: FC<{ data: AreaLandOut }> = ({ data }) => {
  const { t } = useTranslation('place', { keyPrefix: 'building_information' })
  const { showOffice, showLwh, updateDialog } = useFullDetailDialog()
  const { detailType } = useDetailInfo()
  const [isSelectDialogOpen, onOpen, onClose] = useBooleanState()
  const { data: raBuildings, isLoading } = useBuildingsDataV1BuildingsGet({
    pnu: data.pnu,
  })

  if (isLoading || !raBuildings || raBuildings.page.totalPage === 0) {
    return null
  }

  const handleButtonClick = () => {
    if (raBuildings.data.length === 1) {
      showDetail(raBuildings.data[0])
    } else {
      onOpen()
    }
  }

  const showDetail = (item: BuildingsOut) => {
    const isOffice = item.propertyTypeCode === 'PT01_1'
    if (detailType === 'full') {
      updateDialog(item.raId, isOffice ? 'office' : 'lwh')
    } else if (isOffice) {
      showOffice(item.raId)
    } else {
      showLwh(item.raId)
    }
  }

  return (
    <Box>
      <Button
        icon={<IconMarkerBuilding />}
        color="gray"
        variant="line"
        content={t('title')}
        onClick={() => handleButtonClick()}
      />
      <BuildingInformationDialog
        isOpen={isSelectDialogOpen}
        data={data}
        buildings={raBuildings.data}
        onClose={onClose}
        onSelect={showDetail}
      />
    </Box>
  )
}

export default BuildingInformationButton
