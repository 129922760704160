/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.3.1
 */

export type AnalysisLwhLeaseMetricType = typeof AnalysisLwhLeaseMetricType[keyof typeof AnalysisLwhLeaseMetricType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnalysisLwhLeaseMetricType = {
  rentFee: 'rentFee',
  maintenanceFee: 'maintenanceFee',
  emptyRate: 'emptyRate',
} as const;
