import { datadogRum } from '@datadog/browser-rum'
import { isNotNil, isServer } from '@toss/utils'
import { GetUserMeResponse } from '~/libs/apis/service/model'

// eslint-disable-next-line import/no-unused-modules
export const canDatadogRum = () => {
  // check only client and production
  if (process.env.NODE_ENV !== 'production' || isServer() || isNotNil(window.Cypress)) {
    return false
  }

  // rum need datadog env var
  if (isNotNil(process.env.DATADOG_APPLICATION_ID) && isNotNil(process.env.DATADOG_CLIENT_TOKEN)) {
    return true
  }
}

export const initDatadogRum = () => {
  if (canDatadogRum()) {
    datadogRum.init({
      applicationId: process.env.DATADOG_APPLICATION_ID!,
      clientToken: process.env.DATADOG_CLIENT_TOKEN!,
      site: 'datadoghq.com',
      service: 'rsquare-analytics-fe',
      env: process.env.DEPLOY_ENV,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      version: process.env.VERSION,
      beforeSend: (event) => {
        // discard console.error message.
        return !(event.type === 'error' && event.error.source === 'console')
      },
      compressIntakeRequests: true,
    })
  }
}

export const setDatadogRumUser = (me: GetUserMeResponse) => {
  // 기본적으로는 초기화를 하지만 로그인 시점에 초기화가 안되어있을경우 다시 초기화
  if (!datadogRum.getInitConfiguration()) {
    initDatadogRum()
  }
  datadogRum.setUser({ id: `${me.raBusinessPartnerId}_${me.raUserId}` })
}
