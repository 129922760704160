import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import BuildingImageArea from '~/templates/place/detail/overview/BuildingImageArea'
import { LwhOut, OfficeOut, PropertyTypeCode } from '~/libs/apis/data/model'
import Badge from '~/components/Badge/Badge'
import BuildingMapImageArea from '~/templates/place/detail/overview/BuildingMapImageArea'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import useCommonCode from '~/libs/hooks/useCommonCode'
import ChartBarIcon from '~/assets/images/icons/chart-bar.svg'
import CompareIcon from '~/assets/images/icons/compare-line.svg'
import { officeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGet } from '~/libs/apis/data/buildings-office/buildings-office'
import RaDataError from '~/libs/errors/RaDataError'
import { useTranslation } from 'next-i18next'
import { useNextRouter } from '~/libs/hooks/useNextRouter'
import { useState } from 'react'
import useHasPermission from '~/libs/hooks/useHasPermission'
import { PagePermission, PageRoutes } from '~/libs/constants/permission'
import useConstructStatus from '~/libs/hooks/useConstructStatus'
import { useToast } from '~/templates/ToastContextProvider'
import { lwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGet } from '~/libs/apis/data/buildings-lwh/buildings-lwh'
import BuildingActionButton from '~/templates/place/detail/overview/BuildingActionButton'
import { useGetRegistrations } from '~/libs/apis/registration/api'
import { isArrayAndNotEmpty } from '~/libs/utils/format'
import PropertyBadge from '~/templates/common/content/PropertyBadge'

interface OverviewSectionProps {
  data: OfficeOut | LwhOut
  type?: 'office' | 'lwh'
}

const OverviewSection = ({ data, type = 'office' }: OverviewSectionProps) => {
  const isOffice = type === 'office'
  const raId = data?.raId
  const { detailType } = useDetailInfo()
  const { show } = useToast()
  const { codeToName } = useCommonCode()
  const { t } = useTranslation('place', { keyPrefix: 'place_term' })
  const { t: commonTerm } = useTranslation('common', { keyPrefix: 'common_term' })
  const { t: message } = useTranslation('compare', { keyPrefix: 'message' })
  const router = useNextRouter()
  const [isSimilarLoading, setIsSimilarLoading] = useState(false)
  const hasLwhComparePermission = useHasPermission(PagePermission[PageRoutes.compareLwh])
  const hasOfficeComparePermission = useHasPermission(PagePermission[PageRoutes.compareOffice])
  const hasComparePermission = isOffice ? hasOfficeComparePermission : hasLwhComparePermission
  const hasNearByPermission = useHasPermission(PagePermission[PageRoutes.nearby])
  const { isCompletedBuilding } = useConstructStatus(data?.constructStatusCode)
  const propertyType = codeToName(data.propertyTypeCode)
  // TODO: 중복 코드가 보이는데 패턴화 가능한지 검토 필요
  const boeCodeName = codeToName(data.boeTypeCode ?? '')
  const boe = boeCodeName ? ` · ${boeCodeName}` : ''
  const propertyTypeAndBoe = propertyType || boe ? `${propertyType}${boe}` : undefined
  const { data: registrationData } = useGetRegistrations(
    { pnu: data?.pnu ?? '' },
    {
      swr: { enabled: Boolean(data?.pnu), shouldRetryOnError: false },
    },
  )
  const hasRegistrationData = isArrayAndNotEmpty(registrationData)

  const buildingImage = data.imageUrl && <BuildingImageArea imageUrl={data.imageUrl} />
  const boundary = data.buildingBoundary ?? null
  const buildingMapImage = (
    <BuildingMapImageArea
      imageUrl={data.imageUrl}
      position={{ lat: data.lat, lng: data.lng }}
      boundary={boundary}
      markerContent={data.buildingName}
      markerType={type}
    />
  )
  const handleOfficeCompare = () => {
    setIsSimilarLoading(true)
    officeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGet(raId, { similarType: 'lease' })
      .then((similarBuildings) => {
        const raIds = similarBuildings?.map((item) => item.raId)
        raIds?.unshift(raId)
        router
          .push({
            pathname: '/compare',
            query: {
              ids: raIds?.join(','),
              realEstateType: 'building',
              comparePropertyType: PropertyTypeCode.PT01_1,
            },
          })
          .then(() => {
            show(message('organized_comparison_list'))
          })
      })
      .catch((error) => {
        if (error instanceof RaDataError) {
          show(error.responseBody.detail.description)
        } else {
          show(t('common_term.unknown_error'))
        }
      })
      .finally(() => {
        setIsSimilarLoading(false)
      })
  }

  const handleLwhCompare = () => {
    setIsSimilarLoading(true)
    lwhSimilarBuildingDataV1BuildingsLwhRaIdSimilarGet(raId, { similarType: 'lease' })
      .then((similarBuildings) => {
        const raIds = similarBuildings?.map((item) => item.raId)
        raIds?.unshift(raId)
        router
          .push({
            pathname: '/compare',
            query: {
              ids: raIds?.join(','),
              realEstateType: 'building',
              comparePropertyType: PropertyTypeCode.PT01_2,
            },
          })
          .then(() => {
            show(message('organized_comparison_list'))
          })
      })
      .catch((error) => {
        if (error instanceof RaDataError) {
          show(error.responseBody.detail.description)
        } else {
          show(t('common_term.unknown_error'))
        }
      })
      .finally(() => {
        setIsSimilarLoading(false)
      })
  }

  const handleCompare = isOffice ? handleOfficeCompare : handleLwhCompare
  const handleNearbyClick = () => {
    router.push({
      pathname: '/nearby',
      query: {
        nearbyRaId: raId,
        nearbyPropertyType: data.propertyTypeCode,
      },
    })
  }
  const isNearbyAvailable = hasNearByPermission && isCompletedBuilding
  const isCompareAvailable = hasComparePermission && !isSimilarLoading
  const isFull = detailType === 'full'

  return (
    <>
      {isFull ? buildingMapImage : buildingImage}
      <Box
        display="flex"
        flexDirection={isFull ? 'row' : 'column'}
        gap={isFull ? '30px' : '20px'}
        px={isFull ? '30px' : '20px'}
        py={isFull ? '24px' : '20px'}
        justifyContent={isFull ? 'space-between' : undefined}
      >
        <Box flex="1 1 0">
          <Box display="flex" gap="6px" pb="14px">
            {propertyTypeAndBoe && (
              <PropertyBadge
                type={data.propertyTypeCode === 'PT01_1' ? 'office' : 'lwh'}
                content={propertyTypeAndBoe}
              />
            )}
            {!isCompletedBuilding && (
              <Badge
                type="03"
                size="md"
                content={data.constructStatusCode ? (codeToName(data.constructStatusCode) ?? '-') : '-'}
              />
            )}
            {hasRegistrationData && (
              <Badge type="02" size="md" category="blue" content={commonTerm('has_registration')} />
            )}
          </Box>
          <Typography color="gray-800" pb={1}>
            {data.address} {data.indicationRoadAddress && `(${data.indicationRoadAddress})`}
          </Typography>
          <Typography variant="h3" fontWeight="bold" color="gray-800">
            {data.buildingName}
          </Typography>
        </Box>
        <Box display="flex" gap={isFull ? '10px' : '14px'} width={isFull ? '190px' : '360px'}>
          <BuildingActionButton
            isFull={isFull}
            isAvailable={isCompletedBuilding}
            hasPermission={hasNearByPermission}
            handleAction={handleNearbyClick}
            icon={
              <ChartBarIcon
                width={isFull ? '16px' : '20px'}
                color={isNearbyAvailable ? 'var(--color-gray-600)' : 'var(--color-gray-300)'}
              />
            }
            title={t('analyze')}
            subtitle={t('asset_due_diligence_analyze')}
          />
          <BuildingActionButton
            isFull={isFull}
            isAvailable={!isSimilarLoading}
            hasPermission={hasComparePermission}
            handleAction={handleCompare}
            icon={
              <CompareIcon
                width={isFull ? '16px' : '20px'}
                color={isCompareAvailable ? 'var(--color-gray-600)' : 'var(--color-gray-300)'}
              />
            }
            title={t('compare')}
            subtitle={t('similar_building_compare')}
          />
        </Box>
      </Box>
    </>
  )
}

export default OverviewSection
