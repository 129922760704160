import { FC, useMemo } from 'react'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import { useTranslation } from 'next-i18next'
import DataGrid from '~/components/DataGrid/DataGrid'
import { useAreaFormat } from '~/libs/hooks/useFormat'
import { PagerModel } from '@syncfusion/ej2-grids/src/pager/pager-model'

interface FloorGridProps {
  dataSource: Object[] | undefined
  pagerModel?: PagerModel
  onPageChange: (object: { currentPage: number }) => void
}

const FloorGrid: FC<FloorGridProps> = ({ dataSource, pagerModel, onPageChange }) => {
  const { t } = useTranslation()
  const areaFormatter = useAreaFormat()

  const columns: ColumnModel[] = useMemo(
    () => [
      {
        field: 'floorName',
        headerText: t('common_term.floor'),
        textAlign: 'Left',
        valueAccessor: (_: any, data: any) => data.floorName ?? '-',
      },
      {
        field: 'etcStruct',
        headerText: t('common_term.etc_structure'),
        textAlign: 'Left',
        valueAccessor: (_: any, data: any) => data.etcStruct ?? '-',
      },
      {
        field: 'mainPurpose',
        headerText: t('common_term.main_purpose'),
        textAlign: 'Left',
        valueAccessor: (_: any, data: any) => data.mainPurpose ?? '-',
      },
      {
        field: 'area',
        headerText: t('common_term.area'),
        textAlign: 'Left',
        valueAccessor: (_: any, data: any) => areaFormatter(data.area, { rounded: 'n2' }),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <DataGrid
      dataSource={dataSource}
      pagerModel={pagerModel}
      columns={columns}
      paginationMode="server"
      onPageChange={onPageChange}
    />
  )
}

export default FloorGrid
