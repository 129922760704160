/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.3.1
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  CommonCodesOut
} from '../model/commonCodesOut'
import type {
  ExchangesOut
} from '../model/exchangesOut'
import type {
  RaVersionOut
} from '../model/raVersionOut'
import { raApiCall } from '../../../utils/customInstance';
import { customFormData } from '../../../utils/customFormData';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Health check API
 */
export const healthCheckActuatorHealthGet = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<unknown>(
      {url: `/actuator/health`, method: 'GET'
    },
      options);
    }
  

export const getHealthCheckActuatorHealthGetKey = () => [`/actuator/health`] as const;

    
export type HealthCheckActuatorHealthGetQueryResult = NonNullable<Awaited<ReturnType<typeof healthCheckActuatorHealthGet>>>
export type HealthCheckActuatorHealthGetQueryError = unknown

/**
 * @summary Health check API
 */
export const useHealthCheckActuatorHealthGet = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof healthCheckActuatorHealthGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getHealthCheckActuatorHealthGetKey() : null);
  const swrFn = () => healthCheckActuatorHealthGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * Ra backend version
 * @summary RA backend version
 */
export const versionDataV1VersionGet = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RaVersionOut>(
      {url: `/data/v1/version`, method: 'GET'
    },
      options);
    }
  

export const getVersionDataV1VersionGetKey = () => [`/data/v1/version`] as const;

    
export type VersionDataV1VersionGetQueryResult = NonNullable<Awaited<ReturnType<typeof versionDataV1VersionGet>>>
export type VersionDataV1VersionGetQueryError = unknown

/**
 * @summary RA backend version
 */
export const useVersionDataV1VersionGet = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof versionDataV1VersionGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getVersionDataV1VersionGetKey() : null);
  const swrFn = () => versionDataV1VersionGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * RA 테이블 코드 값 조회(공공데이터 제외)
 * @summary 공통 코드 조회 (사용 테이블: ra_v2.d_code_master)
 */
export const commonCodesDataV1CommonCodesGet = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<CommonCodesOut[]>(
      {url: `/data/v1/common-codes`, method: 'GET'
    },
      options);
    }
  

export const getCommonCodesDataV1CommonCodesGetKey = () => [`/data/v1/common-codes`] as const;

    
export type CommonCodesDataV1CommonCodesGetQueryResult = NonNullable<Awaited<ReturnType<typeof commonCodesDataV1CommonCodesGet>>>
export type CommonCodesDataV1CommonCodesGetQueryError = unknown

/**
 * @summary 공통 코드 조회 (사용 테이블: ra_v2.d_code_master)
 */
export const useCommonCodesDataV1CommonCodesGet = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof commonCodesDataV1CommonCodesGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getCommonCodesDataV1CommonCodesGetKey() : null);
  const swrFn = () => commonCodesDataV1CommonCodesGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 환율 조회 (사용 테이블: out_kr.mv_exchanges)
 */
export const getExchangesDataV1ExchangesGet = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<ExchangesOut[]>(
      {url: `/data/v1/exchanges`, method: 'GET'
    },
      options);
    }
  

export const getGetExchangesDataV1ExchangesGetKey = () => [`/data/v1/exchanges`] as const;

    
export type GetExchangesDataV1ExchangesGetQueryResult = NonNullable<Awaited<ReturnType<typeof getExchangesDataV1ExchangesGet>>>
export type GetExchangesDataV1ExchangesGetQueryError = unknown

/**
 * @summary 환율 조회 (사용 테이블: out_kr.mv_exchanges)
 */
export const useGetExchangesDataV1ExchangesGet = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getExchangesDataV1ExchangesGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetExchangesDataV1ExchangesGetKey() : null);
  const swrFn = () => getExchangesDataV1ExchangesGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

