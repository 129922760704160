/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.3.1
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  AreaBoeDataV1AreaBoeGetParams
} from '../model/areaBoeDataV1AreaBoeGetParams'
import type {
  AreaLandOut
} from '../model/areaLandOut'
import type {
  AreaRegionDataV1AreaRegionGetParams
} from '../model/areaRegionDataV1AreaRegionGetParams'
import type {
  BoeOut
} from '../model/boeOut'
import type {
  GetLandDataV1AreaLandGetParams
} from '../model/getLandDataV1AreaLandGetParams'
import type {
  GetOfficialLandPriceDataV1AreaOfficialLandPriceGetParams
} from '../model/getOfficialLandPriceDataV1AreaOfficialLandPriceGetParams'
import type {
  GetOldAgeDataV1AreaOldAgeGetParams
} from '../model/getOldAgeDataV1AreaOldAgeGetParams'
import type {
  GetRealTradePriceDataV1AreaRealTradePriceGetParams
} from '../model/getRealTradePriceDataV1AreaRealTradePriceGetParams'
import type {
  HTTPValidationError
} from '../model/hTTPValidationError'
import type {
  LayerOut
} from '../model/layerOut'
import type {
  RegionOut
} from '../model/regionOut'
import type {
  StructurePlanDataV1AreaStructurePlanGetParams
} from '../model/structurePlanDataV1AreaStructurePlanGetParams'
import type {
  StructurePlanOut
} from '../model/structurePlanOut'
import { raApiCall } from '../../../utils/customInstance';
import { customFormData } from '../../../utils/customFormData';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary 지역 경계 조회 (사용 테이블: ra_v2.mv_boundary_region)
 */
export const areaRegionDataV1AreaRegionGet = (
    params: AreaRegionDataV1AreaRegionGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RegionOut[]>(
      {url: `/data/v1/area/region`, method: 'GET',
        params
    },
      options);
    }
  

export const getAreaRegionDataV1AreaRegionGetKey = (params: AreaRegionDataV1AreaRegionGetParams,) => [`/data/v1/area/region`, ...(params ? [params]: [])] as const;

    
export type AreaRegionDataV1AreaRegionGetQueryResult = NonNullable<Awaited<ReturnType<typeof areaRegionDataV1AreaRegionGet>>>
export type AreaRegionDataV1AreaRegionGetQueryError = HTTPValidationError

/**
 * @summary 지역 경계 조회 (사용 테이블: ra_v2.mv_boundary_region)
 */
export const useAreaRegionDataV1AreaRegionGet = <TError = HTTPValidationError>(
 params: AreaRegionDataV1AreaRegionGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof areaRegionDataV1AreaRegionGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getAreaRegionDataV1AreaRegionGetKey(params) : null);
  const swrFn = () => areaRegionDataV1AreaRegionGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 권역 경계 조회 (사용 테이블: ra_v2.mv_boundary_region)
 */
export const areaBoeDataV1AreaBoeGet = (
    params: AreaBoeDataV1AreaBoeGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<BoeOut[]>(
      {url: `/data/v1/area/boe`, method: 'GET',
        params
    },
      options);
    }
  

export const getAreaBoeDataV1AreaBoeGetKey = (params: AreaBoeDataV1AreaBoeGetParams,) => [`/data/v1/area/boe`, ...(params ? [params]: [])] as const;

    
export type AreaBoeDataV1AreaBoeGetQueryResult = NonNullable<Awaited<ReturnType<typeof areaBoeDataV1AreaBoeGet>>>
export type AreaBoeDataV1AreaBoeGetQueryError = HTTPValidationError

/**
 * @summary 권역 경계 조회 (사용 테이블: ra_v2.mv_boundary_region)
 */
export const useAreaBoeDataV1AreaBoeGet = <TError = HTTPValidationError>(
 params: AreaBoeDataV1AreaBoeGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof areaBoeDataV1AreaBoeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getAreaBoeDataV1AreaBoeGetKey(params) : null);
  const swrFn = () => areaBoeDataV1AreaBoeGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 토지(필지) 경계 조회(사용 테이블: out_kr.mv_public_land_search)
 */
export const getLandDataV1AreaLandGet = (
    params?: GetLandDataV1AreaLandGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<AreaLandOut>(
      {url: `/data/v1/area/land`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetLandDataV1AreaLandGetKey = (params?: GetLandDataV1AreaLandGetParams,) => [`/data/v1/area/land`, ...(params ? [params]: [])] as const;

    
export type GetLandDataV1AreaLandGetQueryResult = NonNullable<Awaited<ReturnType<typeof getLandDataV1AreaLandGet>>>
export type GetLandDataV1AreaLandGetQueryError = HTTPValidationError

/**
 * @summary 토지(필지) 경계 조회(사용 테이블: out_kr.mv_public_land_search)
 */
export const useGetLandDataV1AreaLandGet = <TError = HTTPValidationError>(
 params?: GetLandDataV1AreaLandGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getLandDataV1AreaLandGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetLandDataV1AreaLandGetKey(params) : null);
  const swrFn = () => getLandDataV1AreaLandGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 개발지역 레이어(사용 테이블: ra_v2.mv_buildings_plan_boundary)
 */
export const structurePlanDataV1AreaStructurePlanGet = (
    params?: StructurePlanDataV1AreaStructurePlanGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<StructurePlanOut[]>(
      {url: `/data/v1/area/structure-plan`, method: 'GET',
        params
    },
      options);
    }
  

export const getStructurePlanDataV1AreaStructurePlanGetKey = (params?: StructurePlanDataV1AreaStructurePlanGetParams,) => [`/data/v1/area/structure-plan`, ...(params ? [params]: [])] as const;

    
export type StructurePlanDataV1AreaStructurePlanGetQueryResult = NonNullable<Awaited<ReturnType<typeof structurePlanDataV1AreaStructurePlanGet>>>
export type StructurePlanDataV1AreaStructurePlanGetQueryError = HTTPValidationError

/**
 * @summary 개발지역 레이어(사용 테이블: ra_v2.mv_buildings_plan_boundary)
 */
export const useStructurePlanDataV1AreaStructurePlanGet = <TError = HTTPValidationError>(
 params?: StructurePlanDataV1AreaStructurePlanGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof structurePlanDataV1AreaStructurePlanGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getStructurePlanDataV1AreaStructurePlanGetKey(params) : null);
  const swrFn = () => structurePlanDataV1AreaStructurePlanGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 노후도 레이어(사용 테이블: out_kr.mv_building_age_layer)
 */
export const getOldAgeDataV1AreaOldAgeGet = (
    params?: GetOldAgeDataV1AreaOldAgeGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LayerOut[]>(
      {url: `/data/v1/area/old-age`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetOldAgeDataV1AreaOldAgeGetKey = (params?: GetOldAgeDataV1AreaOldAgeGetParams,) => [`/data/v1/area/old-age`, ...(params ? [params]: [])] as const;

    
export type GetOldAgeDataV1AreaOldAgeGetQueryResult = NonNullable<Awaited<ReturnType<typeof getOldAgeDataV1AreaOldAgeGet>>>
export type GetOldAgeDataV1AreaOldAgeGetQueryError = HTTPValidationError

/**
 * @summary 노후도 레이어(사용 테이블: out_kr.mv_building_age_layer)
 */
export const useGetOldAgeDataV1AreaOldAgeGet = <TError = HTTPValidationError>(
 params?: GetOldAgeDataV1AreaOldAgeGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getOldAgeDataV1AreaOldAgeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetOldAgeDataV1AreaOldAgeGetKey(params) : null);
  const swrFn = () => getOldAgeDataV1AreaOldAgeGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 공시지가 레이어(사용 테이블: out_kr.mv_land_price_layer)
 */
export const getOfficialLandPriceDataV1AreaOfficialLandPriceGet = (
    params?: GetOfficialLandPriceDataV1AreaOfficialLandPriceGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LayerOut[]>(
      {url: `/data/v1/area/official-land-price`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetOfficialLandPriceDataV1AreaOfficialLandPriceGetKey = (params?: GetOfficialLandPriceDataV1AreaOfficialLandPriceGetParams,) => [`/data/v1/area/official-land-price`, ...(params ? [params]: [])] as const;

    
export type GetOfficialLandPriceDataV1AreaOfficialLandPriceGetQueryResult = NonNullable<Awaited<ReturnType<typeof getOfficialLandPriceDataV1AreaOfficialLandPriceGet>>>
export type GetOfficialLandPriceDataV1AreaOfficialLandPriceGetQueryError = HTTPValidationError

/**
 * @summary 공시지가 레이어(사용 테이블: out_kr.mv_land_price_layer)
 */
export const useGetOfficialLandPriceDataV1AreaOfficialLandPriceGet = <TError = HTTPValidationError>(
 params?: GetOfficialLandPriceDataV1AreaOfficialLandPriceGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getOfficialLandPriceDataV1AreaOfficialLandPriceGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetOfficialLandPriceDataV1AreaOfficialLandPriceGetKey(params) : null);
  const swrFn = () => getOfficialLandPriceDataV1AreaOfficialLandPriceGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 실거래가 레이어(사용 테이블: out_kr.mv_real_trade_price_layer)
 */
export const getRealTradePriceDataV1AreaRealTradePriceGet = (
    params?: GetRealTradePriceDataV1AreaRealTradePriceGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LayerOut[]>(
      {url: `/data/v1/area/real-trade-price`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetRealTradePriceDataV1AreaRealTradePriceGetKey = (params?: GetRealTradePriceDataV1AreaRealTradePriceGetParams,) => [`/data/v1/area/real-trade-price`, ...(params ? [params]: [])] as const;

    
export type GetRealTradePriceDataV1AreaRealTradePriceGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRealTradePriceDataV1AreaRealTradePriceGet>>>
export type GetRealTradePriceDataV1AreaRealTradePriceGetQueryError = HTTPValidationError

/**
 * @summary 실거래가 레이어(사용 테이블: out_kr.mv_real_trade_price_layer)
 */
export const useGetRealTradePriceDataV1AreaRealTradePriceGet = <TError = HTTPValidationError>(
 params?: GetRealTradePriceDataV1AreaRealTradePriceGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getRealTradePriceDataV1AreaRealTradePriceGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetRealTradePriceDataV1AreaRealTradePriceGetKey(params) : null);
  const swrFn = () => getRealTradePriceDataV1AreaRealTradePriceGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

