// eslint-disable-next-line import/no-unused-modules
export const customFormData = <T>(requestDTO: T) => {
  const formData = new FormData()

  Object.entries(requestDTO as Object).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        if (item instanceof File) {
          formData.append(key, new Blob([item], { type: item.type }), item.name)
        } else {
          formData.append(key, item)
        }
      })
    } else if (value instanceof File) {
      formData.append(key, new Blob([value], { type: value.type }), value.name)
    } else {
      formData.append(key, value)
    }
  })

  return formData
}
