import { forwardRef, memo } from 'react'
import {
  SelectingEventArgs,
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from '@syncfusion/ej2-react-navigations'
import { EmitType } from '@syncfusion/ej2-base'
import { SelectEventArgs } from '@syncfusion/ej2-navigations/src/tab/tab'
import Typography from '~/components/Typography/Typography'
import styled, { css } from '@xstyled/styled-components'
import { TextVariants } from '~/libs/theme/fonts'
import * as CSS from 'csstype'
import { isDifferentArray } from '@toss/utils'

type TabSize = 'sm' | 'md' | 'lg' | 'xl'
type TabType = 'type1' | 'type2' | 'type3'
type TabColor = 'primary' | 'black'

const propsAreEqual = (prev: TabProps, next: TabProps) => {
  return !isDifferentArray(prev.tabItems, next.tabItems) && prev.selectedItem === next.selectedItem
}

interface TabProps {
  selectedItem?: number
  tabItems: string[]
  selected?: EmitType<SelectEventArgs>
  selecting?: EmitType<SelectingEventArgs>
  size?: TabSize
  type?: TabType
  fullWidth?: boolean
  itemwidth?: number
  color?: TabColor
}

const fontSize: Record<TabSize, TextVariants> = {
  sm: 'caption1',
  md: 'body',
  lg: 'subtitle',
  xl: 'h5',
}

const tabHeight: Record<TabSize, CSS.Property.Height> = {
  sm: '35px',
  md: '37px',
  lg: '56px',
  xl: '60px',
}

const Tab = forwardRef<TabComponent, TabProps>(
  (
    {
      selectedItem = 0,
      tabItems,
      selected,
      selecting,
      size = 'md',
      type = 'type1',
      fullWidth = false,
      itemwidth,
      color = 'primary',
    },
    ref,
  ) => {
    // eslint-disable-next-line react/display-name,sonarjs/no-unstable-nested-components
    const createHeaderTemplate = (header: string, size: TabSize) => () => (
      <Typography color="inherit" fontWeight="inherit" fontSize={fontSize[size]}>
        {header}
      </Typography>
    )

    return (
      <StyledTab
        ref={ref}
        selectedItem={selectedItem}
        selected={selected}
        selecting={selecting}
        type={type}
        size={size}
        className={fullWidth && 'e-fullwidth'}
        itemwidth={itemwidth}
        color={color}
      >
        <TabItemsDirective>
          {tabItems.map((tabItem, index) => (
            // eslint-disable-next-line sonarjs/no-array-index-key
            <TabItemDirective key={index} headerTemplate={createHeaderTemplate(tabItem, size)} />
          ))}
        </TabItemsDirective>
      </StyledTab>
    )
  },
)

Tab.displayName = 'Tab'

const StyledTab = styled(TabComponent)<
  Pick<TabProps, 'selected' | 'type' | 'size' | 'fullWidth' | 'itemwidth' | 'color'>
>`
  &.e-tab {
    &.e-fullwidth {
      .e-tab-header {
        .e-toolbar-items {
          width: calc(100% - 1px);
          margin: 0 auto;
          display: flex;
        }
      }

      .e-item,
      .e-toolbar-item {
        flex: 1 1;
      }
    }

    .e-tab-header {
      &::before {
        ${(p) => css({ borderColor: p.type === 'type3' ? 'var(--color-gray-400)' : 'transparent' })}
      }

      .e-indicator {
        display: block;
        ${(p) => css({ backgroundColor: p.color === 'black' ? 'gray-800' : 'purple-700' })}
      }

      .e-toolbar-items {
        height: auto;
      }
    }

    .e-toolbar-item {
      ${(p) => css({ width: `${p.itemwidth ? p.itemwidth + 'px' : 'auto'} !important` })}
      ${(p) => css({ height: `${tabHeight[p.size!]} !important` })}
            .e-tab-text {
        font-family: var(--font-family) !important;
      }

      &.e-active {
        border: none;

        ${(p) =>
          // eslint-disable-next-line sonarjs/no-nested-conditional
          css({ background: p.type === 'type1' ? 'white' : p.type === 'type2' ? 'var(--color-blue-200)' : 'grey-100' })}
        .e-tab-wrap .e-tab-text,
                .e-tab-wrap:hover .e-tab-text {
          ${(p) => css({ color: p.color === 'black' ? 'gray-800' : 'purple-700' })}
          font-weight: bold;
        }
      }

      .e-tab-wrap {
        padding: 0 16px;
        text-align: center;

        &:hover {
          border-color: transparent;
        }
      }

      .e-tab-wrap,
      .e-tab-text,
      .e-tab-wrap:hover {
        .e-tab-text {
          color: var(--color-gray-600);
          font-weight: semibold;
        }
      }
    }
  }
`

export default memo(Tab, propsAreEqual)
