import { FC } from 'react'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import { Page } from '~/libs/apis/data/model'
import LoadMoreDataGrid from '~/templates/place/detail/LoadMoreDataGrid'
import { useDetailInfo } from '~/templates/DetailTypeProvider'

interface BuildingTradeGridProps {
  dataSource: Object[] | undefined
  pageModel: Page | undefined
  onPageChange: (page: number) => void
  fullColumns: ColumnModel[]
  summaryColumns: ColumnModel[]
}

const BuildingTradeGrid: FC<BuildingTradeGridProps> = ({
  dataSource,
  pageModel,
  onPageChange,
  fullColumns,
  summaryColumns,
}) => {
  const { detailType } = useDetailInfo()
  const isFull = detailType === 'full'

  return (
    <LoadMoreDataGrid
      width={isFull ? '1400px' : '100%'}
      columns={isFull ? fullColumns : summaryColumns}
      dataSource={dataSource}
      pageModel={pageModel}
      onPageChange={onPageChange}
      allowSelection={false}
    />
  )
}

export default BuildingTradeGrid
