import Head from 'next/head'
import React, { ReactElement, ReactNode, useEffect } from 'react'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import GlobalStyle from '~/templates/GlobalStyle'
import useAuth from '~/libs/hooks/useAuth'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { ThemeProvider } from '@xstyled/styled-components'
import { theme } from '~/libs/theme'
import { L10n, loadCldr, registerLicense } from '@syncfusion/ej2-base'
import '@syncfusion/ej2/bootstrap4.css'
import { SWRConfig } from 'swr'
import { RecoilEnv, RecoilRoot } from 'recoil'
import DefaultLayout from '~/templates/DefaultLayout'
import 'core-js/features/array/to-reversed'
import 'core-js/features/array/to-sorted'
import 'core-js/features/array/to-spliced'
import 'core-js/features/array/with'
import { gregorian, numberingSystems, numbers, timeZoneNames, weekData } from '~/public/locales/ko/cldr-data'
import { PostUsersSignInResponse } from '~/libs/apis/service/model'
import { setAccessToken } from '~/libs/utils/customInstance'
import { datadogRum } from '@datadog/browser-rum'
import { useCacheProvider } from '@piotr-cz/swr-idb-cache'
import commonDataStorageHandler from '~/libs/utils/commonDataStorageHandler'
import { GoogleTagManager } from '@next/third-parties/google'
import NewVersionError from '~/libs/errors/NewVersionError'
import useNewVersionErrorDialog from '~/libs/hooks/useNewVersionErrorDialog'
import ErrorBoundary from '~/components/Error/ErrorBoundary'
import ErrorTemplate from '~/templates/ErrorTemplate'
import { initDatadogRum } from '~/libs/utils/rum'

export type GetLayout = (page: ReactElement) => ReactNode
export type NextPageWithLayout = NextPage & {
  getLayout?: GetLayout
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

registerLicense(process.env.SYNCFUSION_LICENSE_KEY!)
RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = process.env.NODE_ENV === 'production'

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('~/mocks')
}

initDatadogRum()

const defaultLayout: GetLayout = (page) => {
  return <DefaultLayout>{page}</DefaultLayout>
}

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const { hydrateLoggedIn, handleErrorRetry } = useAuth()
  const getLayout = Component.getLayout ?? defaultLayout
  const cacheProvider = useCacheProvider({
    dbName: 'ra-db',
    storeName: 'common-data',
    storageHandler: commonDataStorageHandler,
  })
  const { t } = useTranslation('common')
  const { t: pdf } = useTranslation('common', { keyPrefix: 'pdf_viewer' })
  const showNewVersionErrorDialog = useNewVersionErrorDialog()

  useEffect(() => {
    const handleStorageEvent = (event: StorageEvent) => {
      if (event.key === 'auth' && event.newValue) {
        const authData = JSON.parse(event.newValue) as PostUsersSignInResponse
        if (authData) {
          setAccessToken(authData)
        } else {
          datadogRum.addError(new Error('auth data error'))
        }
      }
    }
    window.addEventListener('storage', handleStorageEvent)
    return () => {
      window.removeEventListener('storage', handleStorageEvent)
    }
  }, [])

  hydrateLoggedIn()

  useEffect(() => {
    loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData)
    L10n.load({
      ko: {
        grid: {
          EmptyRecord: t('message.no_data'),
        },
        datepicker: {
          today: t('common_term.today'),
        },
        PdfViewer: {
          PdfViewer: pdf('pdf_viewer'),
          cancel: pdf('cancel'),
          'Download file': pdf('download_file'),
          download: pdf('download'),
          'Go To First Page': pdf('go_to_first_page'),
          'Next Page': pdf('next_page'),
          OK: pdf('ok'),
          'Page Number': pdf('page_number'),
          'Previous Page': pdf('previous_page'),
          'Go To Last Page': pdf('go_to_last_page'),
          Zoom: pdf('zoom'),
          'Zoom In': pdf('zoom_in'),
          'Zoom Out': pdf('zoom_out'),
          'Page Thumbnails': pdf('page_thumbnails'),
          Bookmarks: pdf('bookmarks'),
          Print: pdf('print'),
          Copy: pdf('copy'),
          'Text Selection': pdf('text_selection'),
          Panning: pdf('panning'),
          'Text Search': pdf('text_search'),
          'Find in document': pdf('find_in_document'),
          'Match case': pdf('match_case'),
          Apply: pdf('apply'),
          GoToPage: pdf('go_to_page'),
          'No matches': pdf('no_matches'),
          'No Text Found': pdf('no_text_found'),
          Undo: pdf('undo'),
          'First text': pdf('first_text'),
          'Previous text': pdf('previous_text'),
          'Next text': pdf('next_text'),
          'Last text': pdf('last_text'),
          'Zoom in text': pdf('zoom_in_text'),
          'Zoom out text': pdf('zoom_out_text'),
          'Pan text': pdf('pan_text'),
          'Print text': pdf('print_text'),
          'Search text': pdf('search_text'),
        },
      },
    })
  }, [t, pdf])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ErrorBoundary fallback={<ErrorTemplate />}>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            provider: cacheProvider,
            onErrorRetry: handleErrorRetry,
            onError: (error) => {
              if (error instanceof NewVersionError) {
                showNewVersionErrorDialog()
              }
            },
          }}
        >
          <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <title>{process.env.META_TITLE}</title>
          </Head>

          <RecoilRoot>{cacheProvider ? getLayout(<Component {...pageProps} />) : null}</RecoilRoot>
          {process.env.GTM_ID && <GoogleTagManager gtmId={process.env.GTM_ID} />}
        </SWRConfig>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default appWithTranslation(MyApp)
