/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.3.1
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  HTTPValidationError
} from '../model/hTTPValidationError'
import type {
  SearchBuildingDataV1SearchBuildingGetParams
} from '../model/searchBuildingDataV1SearchBuildingGetParams'
import type {
  SearchBuildingOut
} from '../model/searchBuildingOut'
import type {
  SearchClientDataV1SearchClientGetParams
} from '../model/searchClientDataV1SearchClientGetParams'
import type {
  SearchClientOut
} from '../model/searchClientOut'
import type {
  SearchLandDataV1SearchLandGetParams
} from '../model/searchLandDataV1SearchLandGetParams'
import type {
  SearchLandOut
} from '../model/searchLandOut'
import type {
  SearchRegionDataV1SearchRegionGetParams
} from '../model/searchRegionDataV1SearchRegionGetParams'
import type {
  SearchRegionOut
} from '../model/searchRegionOut'
import type {
  SearchRegistrationOwnerOut
} from '../model/searchRegistrationOwnerOut'
import type {
  SearchSubwayDataV1SearchRegistrationOwnerGetParams
} from '../model/searchSubwayDataV1SearchRegistrationOwnerGetParams'
import type {
  SearchSubwayDataV1SearchSubwayGetParams
} from '../model/searchSubwayDataV1SearchSubwayGetParams'
import type {
  SearchSubwayOut
} from '../model/searchSubwayOut'
import type {
  SearchTotalDataV1SearchGetParams
} from '../model/searchTotalDataV1SearchGetParams'
import type {
  TotalSearchOut
} from '../model/totalSearchOut'
import { raApiCall } from '../../../utils/customInstance';
import { customFormData } from '../../../utils/customFormData';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary 전체 검색
 */
export const searchTotalDataV1SearchGet = (
    params: SearchTotalDataV1SearchGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<TotalSearchOut>(
      {url: `/data/v1/search`, method: 'GET',
        params
    },
      options);
    }
  

export const getSearchTotalDataV1SearchGetKey = (params: SearchTotalDataV1SearchGetParams,) => [`/data/v1/search`, ...(params ? [params]: [])] as const;

    
export type SearchTotalDataV1SearchGetQueryResult = NonNullable<Awaited<ReturnType<typeof searchTotalDataV1SearchGet>>>
export type SearchTotalDataV1SearchGetQueryError = HTTPValidationError

/**
 * @summary 전체 검색
 */
export const useSearchTotalDataV1SearchGet = <TError = HTTPValidationError>(
 params: SearchTotalDataV1SearchGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof searchTotalDataV1SearchGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSearchTotalDataV1SearchGetKey(params) : null);
  const swrFn = () => searchTotalDataV1SearchGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 빌딩 검색
 */
export const searchBuildingDataV1SearchBuildingGet = (
    params: SearchBuildingDataV1SearchBuildingGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SearchBuildingOut[]>(
      {url: `/data/v1/search/building`, method: 'GET',
        params
    },
      options);
    }
  

export const getSearchBuildingDataV1SearchBuildingGetKey = (params: SearchBuildingDataV1SearchBuildingGetParams,) => [`/data/v1/search/building`, ...(params ? [params]: [])] as const;

    
export type SearchBuildingDataV1SearchBuildingGetQueryResult = NonNullable<Awaited<ReturnType<typeof searchBuildingDataV1SearchBuildingGet>>>
export type SearchBuildingDataV1SearchBuildingGetQueryError = HTTPValidationError

/**
 * @summary 빌딩 검색
 */
export const useSearchBuildingDataV1SearchBuildingGet = <TError = HTTPValidationError>(
 params: SearchBuildingDataV1SearchBuildingGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof searchBuildingDataV1SearchBuildingGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSearchBuildingDataV1SearchBuildingGetKey(params) : null);
  const swrFn = () => searchBuildingDataV1SearchBuildingGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 지역 검색
 */
export const searchRegionDataV1SearchRegionGet = (
    params: SearchRegionDataV1SearchRegionGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SearchRegionOut[]>(
      {url: `/data/v1/search/region`, method: 'GET',
        params
    },
      options);
    }
  

export const getSearchRegionDataV1SearchRegionGetKey = (params: SearchRegionDataV1SearchRegionGetParams,) => [`/data/v1/search/region`, ...(params ? [params]: [])] as const;

    
export type SearchRegionDataV1SearchRegionGetQueryResult = NonNullable<Awaited<ReturnType<typeof searchRegionDataV1SearchRegionGet>>>
export type SearchRegionDataV1SearchRegionGetQueryError = HTTPValidationError

/**
 * @summary 지역 검색
 */
export const useSearchRegionDataV1SearchRegionGet = <TError = HTTPValidationError>(
 params: SearchRegionDataV1SearchRegionGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof searchRegionDataV1SearchRegionGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSearchRegionDataV1SearchRegionGetKey(params) : null);
  const swrFn = () => searchRegionDataV1SearchRegionGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 필지 검색
 */
export const searchLandDataV1SearchLandGet = (
    params: SearchLandDataV1SearchLandGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SearchLandOut[]>(
      {url: `/data/v1/search/land`, method: 'GET',
        params
    },
      options);
    }
  

export const getSearchLandDataV1SearchLandGetKey = (params: SearchLandDataV1SearchLandGetParams,) => [`/data/v1/search/land`, ...(params ? [params]: [])] as const;

    
export type SearchLandDataV1SearchLandGetQueryResult = NonNullable<Awaited<ReturnType<typeof searchLandDataV1SearchLandGet>>>
export type SearchLandDataV1SearchLandGetQueryError = HTTPValidationError

/**
 * @summary 필지 검색
 */
export const useSearchLandDataV1SearchLandGet = <TError = HTTPValidationError>(
 params: SearchLandDataV1SearchLandGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof searchLandDataV1SearchLandGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSearchLandDataV1SearchLandGetKey(params) : null);
  const swrFn = () => searchLandDataV1SearchLandGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 지하철역 검색
 */
export const searchSubwayDataV1SearchSubwayGet = (
    params: SearchSubwayDataV1SearchSubwayGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SearchSubwayOut[]>(
      {url: `/data/v1/search/subway`, method: 'GET',
        params
    },
      options);
    }
  

export const getSearchSubwayDataV1SearchSubwayGetKey = (params: SearchSubwayDataV1SearchSubwayGetParams,) => [`/data/v1/search/subway`, ...(params ? [params]: [])] as const;

    
export type SearchSubwayDataV1SearchSubwayGetQueryResult = NonNullable<Awaited<ReturnType<typeof searchSubwayDataV1SearchSubwayGet>>>
export type SearchSubwayDataV1SearchSubwayGetQueryError = HTTPValidationError

/**
 * @summary 지하철역 검색
 */
export const useSearchSubwayDataV1SearchSubwayGet = <TError = HTTPValidationError>(
 params: SearchSubwayDataV1SearchSubwayGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof searchSubwayDataV1SearchSubwayGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSearchSubwayDataV1SearchSubwayGetKey(params) : null);
  const swrFn = () => searchSubwayDataV1SearchSubwayGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 등기 소유자 검색
 */
export const searchSubwayDataV1SearchRegistrationOwnerGet = (
    params: SearchSubwayDataV1SearchRegistrationOwnerGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SearchRegistrationOwnerOut[]>(
      {url: `/data/v1/search/registration/owner`, method: 'GET',
        params
    },
      options);
    }
  

export const getSearchSubwayDataV1SearchRegistrationOwnerGetKey = (params: SearchSubwayDataV1SearchRegistrationOwnerGetParams,) => [`/data/v1/search/registration/owner`, ...(params ? [params]: [])] as const;

    
export type SearchSubwayDataV1SearchRegistrationOwnerGetQueryResult = NonNullable<Awaited<ReturnType<typeof searchSubwayDataV1SearchRegistrationOwnerGet>>>
export type SearchSubwayDataV1SearchRegistrationOwnerGetQueryError = HTTPValidationError

/**
 * @summary 등기 소유자 검색
 */
export const useSearchSubwayDataV1SearchRegistrationOwnerGet = <TError = HTTPValidationError>(
 params: SearchSubwayDataV1SearchRegistrationOwnerGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof searchSubwayDataV1SearchRegistrationOwnerGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSearchSubwayDataV1SearchRegistrationOwnerGetKey(params) : null);
  const swrFn = () => searchSubwayDataV1SearchRegistrationOwnerGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 기업명 검색
 */
export const searchClientDataV1SearchClientGet = (
    params: SearchClientDataV1SearchClientGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SearchClientOut[]>(
      {url: `/data/v1/search/client`, method: 'GET',
        params
    },
      options);
    }
  

export const getSearchClientDataV1SearchClientGetKey = (params: SearchClientDataV1SearchClientGetParams,) => [`/data/v1/search/client`, ...(params ? [params]: [])] as const;

    
export type SearchClientDataV1SearchClientGetQueryResult = NonNullable<Awaited<ReturnType<typeof searchClientDataV1SearchClientGet>>>
export type SearchClientDataV1SearchClientGetQueryError = HTTPValidationError

/**
 * @summary 기업명 검색
 */
export const useSearchClientDataV1SearchClientGet = <TError = HTTPValidationError>(
 params: SearchClientDataV1SearchClientGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof searchClientDataV1SearchClientGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSearchClientDataV1SearchClientGetKey(params) : null);
  const swrFn = () => searchClientDataV1SearchClientGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

