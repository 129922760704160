import {
  ExcelExport,
  Filter,
  Freeze,
  GridComponent,
  InfiniteScroll,
  Inject,
  Page,
  Resize,
  Selection,
  Sort,
} from '@syncfusion/ej2-react-grids'
import React, { forwardRef, useCallback, useEffect, useRef } from 'react'
import { Box } from '~/components'
import { GridModel } from '@syncfusion/ej2-grids'
import { EmitType } from '@syncfusion/ej2-base'
import { PagerModel } from '@syncfusion/ej2-grids/src/pager/pager-model'
import Pager, { pageCss } from '~/components/DataGrid/Pager'
import { styled } from '@xstyled/styled-components'
import { DefaultHtmlAttributes } from '@syncfusion/ej2-react-base'
import { isNonEmptyArray } from '@toss/utils'
import NoData from '~/components/NoData'

type PaginationMode = 'client' | 'server' | 'infinite' | undefined

// eslint-disable-next-line import/no-unused-modules
export interface DataGridServerPagerModel {
  paginationMode?: PaginationMode
  pagerModel?: PagerModel
  onPageChange?: EmitType<Object>
  onPageSizeChange?: EmitType<Object>
}

export interface DataGridProps extends GridModel, DataGridServerPagerModel, Pick<DefaultHtmlAttributes, 'id'> {
  isLoading?: boolean
  fillPageSize?: boolean
}

const DataGrid = forwardRef<GridComponent, DataGridProps>(
  (
    {
      onPageChange,
      onPageSizeChange,
      paginationMode,
      pagerModel,
      isLoading = false,
      fillPageSize = true,
      ...gridModelProps
    },
    ref,
  ) => {
    const gridRef = useRef<GridComponent>()
    const refCallback = (gridComponent: GridComponent) => {
      gridRef.current = gridComponent
      if (gridComponent && paginationMode === 'client') {
        if (onPageChange) {
          gridComponent.pagerModule.pagerObj.click = onPageChange
        }
        if (onPageSizeChange) {
          gridComponent.pagerModule.pagerObj.dropDownChanged = onPageSizeChange
        }
      }
      if (ref) {
        if (typeof ref === 'function') {
          ref(gridComponent)
        } else {
          ref.current = gridComponent
        }
      }
    }

    const services: Object[] = [Selection, Freeze, Resize]

    const props: GridModel = {
      locale: 'ko',
      enableHover: (!!gridModelProps.recordClick || gridModelProps.enableHover) ?? false,
      ...gridModelProps,
    }

    if (paginationMode === 'client') {
      props.allowPaging = true
      props.pageSettings ??= pagerModel
      services.push(Page)
    }

    if (paginationMode === 'infinite') {
      props.enableInfiniteScrolling = true
      props.pageSettings ??= pagerModel
      services.push(InfiniteScroll)
    }

    if (gridModelProps.allowExcelExport) {
      services.push(ExcelExport)
    }

    if (gridModelProps.allowSorting) {
      services.push(Sort)
    }

    if (gridModelProps.allowFiltering) {
      services.push(Filter)
    }

    gridModelProps.columns?.forEach((column) => {
      if (typeof column === 'object' && !column.type) {
        column.type = 'string'
      }
    })

    if (fillPageSize) {
      const dataBound = gridModelProps.dataBound
      props.dataBound = (args: Object) => {
        if (!gridRef.current) {
          return
        }

        const currentViewRecords = gridRef.current?.getCurrentViewRecords()
        if (isNonEmptyArray(currentViewRecords) && pagerModel?.pageSize) {
          const emptyCount = pagerModel?.pageSize - currentViewRecords.length
          for (let i = 0; i < emptyCount; i++) {
            const contentTable = gridRef.current.getContentTable() as HTMLTableElement
            if (contentTable.rows.length >= pagerModel?.pageSize) {
              break
            }
            const row = contentTable.insertRow(-1) // We are adding at the end
            row.classList.add('e-row', 'e-empty-row')
            for (let j = 0; j < gridRef.current?.getVisibleColumns().length; j++) {
              const cell = row.insertCell(j)
              cell.classList.add('e-rowcell')
            }
          }
        }
        if (dataBound) {
          dataBound(args)
        }
      }
    }

    const emptyRecordTemplate = useCallback(
      () => <NoData height="390px" backgroundColor="system-white" borderRadius={0} />,
      [],
    )

    useEffect(() => {
      if (isLoading) {
        gridRef.current?.showMaskRow()
      } else {
        gridRef.current?.removeMaskRow()
      }
    }, [isLoading])

    return (
      <Box>
        <StyledGridComponent
          ref={refCallback}
          cssClass={props.allowSelection ? 'is-selection' : undefined}
          emptyRecordTemplate={emptyRecordTemplate}
          {...props}
        >
          <Inject services={services} />
        </StyledGridComponent>
        {paginationMode === 'server' && (
          <Pager click={onPageChange} dropDownChanged={onPageSizeChange} {...pagerModel} />
        )}
      </Box>
    )
  },
)

export default DataGrid

DataGrid.displayName = 'DataGrid'

const StyledGridComponent = styled(GridComponent)`
  &.e-grid {
    width: 100%;
    border: none;
    border-top: 1px solid var(--color-gray-300);
    border-radius: 0;

    // 전체 테이블

    .e-table:not(:has(.e-emptyrow)) {
      border: none;
      border-spacing: 0;
      border-collapse: collapse;
    }

    // 헤더 행

    .e-gridheader {
      border-top: none;

      .e-columnheader {
        .e-headercell {
          background-color: var(--color-gray-200);
          vertical-align: middle;
          height: auto;
          padding: 13px 10px;

          border-left: 1px solid var(--color-gray-300) !important;
          border-right: 1px solid var(--color-gray-300) !important;

          .e-headercelldiv {
            height: auto;
            margin-left: 0 !important;
            margin-right: 0 !important;
            padding: 0;
          }

          .e-headercelldiv > .e-headertext {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: 1px solid var(--color-gray-800);
          }
        }

        .e-headercell .e-sortfilterdiv.e-icons {
          width: 22px;
        }
      }
    }

    // 컨텐트 행

    .e-gridcontent {
      .e-headercell,
      .e-rowcell,
      .e-gridheader tr th:first-child,
      .e-gridheader tr th:last-child {
        padding: 0;
      }

      tr.e-row {
        border: 1px solid var(--color-gray-300);

        &:first-child {
          border-top: 1px solid var(--color-system-white);
        }

        .e-selectionbackground {
          border: none;
          box-shadow: none;
        }

        .e-rowcell {
          color: var(--color-gray-800);
          font-size: 14px;
          padding: 10px;
          line-height: 20px;
          border: none;

          border-left: 1px solid var(--color-gray-300);

          &.e-focused.e-focus {
            box-shadow: none;
          }

          &:empty {
            height: 41px;
          }
        }

        &:nth-child(even),
        &:nth-child(even) td.e-active {
          background-color: var(--color-gray-100);
        }

        &:nth-child(odd),
        &:nth-child(odd) td.e-active {
          background-color: var(--color-system-white);
        }

        &:hover {
          &:nth-child(odd) .e-rowcell {
            background: var(--color-system-white) !important;
            color: var(--color-gray-800);
          }

          &:nth-child(even) .e-rowcell {
            background: var(--color-gray-100) !important;
            color: var(--color-gray-800);
          }
        }

        &.e-disabled {
          opacity: 1;

          .e-rowcell:hover {
            cursor: default;
          }
        }

        &.mouseover {
          cursor: pointer;
        }
      }

      &:has(.e-emptyrow) {
        height: inherit;
      }

      .e-emptyrow {
        height: 100%;

        td {
          border-style: solid;
          border-color: var(--color-gray-300);
          border-width: 0 1px 1px 1px !important;
          padding: 0 !important;
          text-align: center;
        }
      }
    }

    // 선택이 가능한 표일 경우에만

    &.is-selection .e-gridcontent {
      .e-row:not(.e-empty-row):hover,
      .e-row:has(.e-selectionbackground) {
        border: 1px double var(--color-purple-600);
        cursor: pointer;
      }

      .e-row .e-rowcell.e-selectionbackground {
        font-weight: 600;
      }
    }

    &.e-gridhover .e-gridcontent {
      .e-row:not(.e-empty-row):hover,
      .e-row:has(.e-hovered) {
        border: 1px double var(--color-purple-600);
        cursor: pointer;
      }
    }

    .e-pager {
      ${pageCss}
    }

    .e-leftfreeze {
      background-color: inherit;
    }
  }
`
