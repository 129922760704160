import { useRouter } from 'next/router'
import { useRef } from 'react'
import { SWRConfiguration, useSWRConfig } from 'swr'
import { PostUsersSignInResponse } from '~/libs/apis/service/model'
import RaDataError from '~/libs/errors/RaDataError'
import { getAuthData, setAuthData, truncateAuthData } from '~/libs/utils/auth'
import { setAccessToken } from '~/libs/utils/customInstance'
import { datadogRum } from '@datadog/browser-rum'
import { DOWNTIME_NOTICE_CLOSED_KEY } from '~/libs/constants/common'

const useAuth = () => {
  const router = useRouter()
  const { cache } = useSWRConfig()
  const isExpired = useRef(false)

  const isLoggedIn = () => {
    const authData = getAuthData()

    if (authData !== undefined) {
      const { accessToken } = authData
      return accessToken !== ''
    }

    return false
  }

  const hydrateLoggedIn = () => {
    const authData = getAuthData()

    if (isLoggedIn() && authData !== undefined) {
      setAccessToken(authData)
    }
  }

  const login = (data: PostUsersSignInResponse) => {
    setAuthData(data)
    setAccessToken(data)
  }

  const logout = async (returnUrl?: boolean) => {
    for (const key of cache.keys()) {
      cache.delete(key)
    }
    await truncateAuthData()
    localStorage.removeItem(DOWNTIME_NOTICE_CLOSED_KEY)
    setAccessToken(undefined)

    datadogRum.clearUser()

    if (returnUrl) {
      await router.push({ pathname: '/login', query: { returnUrl: router.asPath } })
    } else {
      await router.replace('/login')
    }
  }

  const handleErrorRetry: SWRConfiguration['onErrorRetry'] = (error, key, config, revalidate, { retryCount }) => {
    if (error instanceof RaDataError && error.status === 401 && !isExpired.current) {
      isExpired.current = true
      logout()
      return
    }

    setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval)
  }

  return {
    login,
    isLoggedIn,
    hydrateLoggedIn,
    logout,
    handleErrorRetry,
  }
}

export default useAuth
