import { forwardRef, PropsWithChildren, useCallback, useLayoutEffect, useState } from 'react'
import Popper, { PopperPosition, PopperProps } from '~/components/Dropdown/Popper'

export interface PopoverProps extends Omit<PopperProps, 'anchorEl'> {
  open?: boolean
  /** Popover의 width과 position 정보를 참조할 HTMLElement 지정 */
  anchorEl: HTMLElement | null
  getPosition?: (anchorElRect: DOMRect) => PopperPosition
}

const getBottomLeftPosition = (anchorElRect: DOMRect): PopperPosition => {
  return {
    top: anchorElRect.bottom + window.scrollY,
    left: anchorElRect.left + window.scrollX,
  }
}

const Popover = forwardRef<HTMLDivElement, PropsWithChildren<PopoverProps>>(
  ({ open, onClose, anchorEl, top, bottom, left, right, getPosition = getBottomLeftPosition, ...props }, ref) => {
    const [pos, setPos] = useState<PopperPosition>()

    const handlePosition = useCallback(() => {
      if (anchorEl) {
        const anchorElRect = anchorEl.getBoundingClientRect()
        setPos(getPosition(anchorElRect))
      }
    }, [anchorEl, getPosition])

    const handleResize = useCallback(() => {
      handlePosition()
    }, [handlePosition])

    useLayoutEffect(() => {
      handlePosition()
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }, [handlePosition, handleResize])

    if (!open) {
      return
    }

    return (
      <Popper
        ref={ref}
        anchorEl={anchorEl}
        onClose={onClose}
        top={pos?.top}
        left={pos?.left}
        right={pos?.right}
        bottom={pos?.bottom}
        {...props}
      />
    )
  },
)

Popover.displayName = 'Popover'

export default Popover
