import { FC, ReactNode } from 'react'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import { useTranslation } from 'next-i18next'
import Tooltip from '~/components/Tooltip/Tooltip'

interface BuildingActionButtonProps {
  isFull: boolean
  isAvailable: boolean
  hasPermission: boolean
  handleAction: () => void
  icon: ReactNode
  title: string
  subtitle: string
}
const BuildingActionButton: FC<BuildingActionButtonProps> = ({
  isFull,
  isAvailable,
  hasPermission,
  handleAction,
  icon,
  title,
  subtitle,
}) => {
  const { t } = useTranslation()
  const isActiveStyle = isAvailable && hasPermission

  const Content = () => (
    <Box display="flex" py={isFull ? '16px' : '10px'} px={4} justifyContent="space-between">
      <Box>
        {isFull && icon}
        <Typography fontWeight="semibold" color={isActiveStyle ? 'gray-800' : 'gray-500'} pt={isFull ? 2 : undefined}>
          {title}
        </Typography>
        <Typography variant="caption2" color={isActiveStyle ? 'gray-700' : 'gray-500'}>
          {subtitle}
        </Typography>
      </Box>
      {!isFull && icon}
    </Box>
  )

  return (
    <Box
      flex={1}
      backgroundColor={isActiveStyle ? 'gray-200' : 'gray-100'}
      borderRadius={8}
      cursor={isActiveStyle ? 'pointer' : undefined}
      onClick={isActiveStyle ? handleAction : undefined}
    >
      {hasPermission ? (
        <Content />
      ) : (
        <Tooltip content={t('common:no_permission.has_no_permission_tooltip')} position="BottomCenter" opensOn="Hover">
          <Content />
        </Tooltip>
      )}
    </Box>
  )
}

export default BuildingActionButton
