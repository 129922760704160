import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import { EmitType } from '@syncfusion/ej2-base'
import { RecordClickEventArgs, RowDataBoundEventArgs } from '@syncfusion/ej2-grids/src/grid/base/interface'
import DataGrid, { DataGridProps } from '~/components/DataGrid/DataGrid'
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { Page } from '~/libs/apis/data/model'
import { GridComponent } from '@syncfusion/ej2-react-grids'

interface RegistrationGridProps extends DataGridProps {
  activeIndex?: number | undefined
  setActiveIndex?: Dispatch<SetStateAction<number | undefined>>
  page?: Page
}

const RegistrationGrid = ({
  columns,
  dataSource,
  activeIndex: activeIndexProps,
  setActiveIndex: setActiveIndexProps,
  page,
  onPageChange,
}: RegistrationGridProps) => {
  // eslint-disable-next-line sonarjs/hook-use-state
  const activeIndexState = useState<number | undefined>()
  const { showLand } = useFullDetailDialog()

  const activeIndex = activeIndexProps ?? activeIndexState[0]
  const setActiveIndex = setActiveIndexProps ?? activeIndexState[1]

  const ref = useRef<GridComponent>(null)

  const pagerModel = useMemo(
    () =>
      page
        ? {
            currentPage: page.page,
            pageCount: 5,
            pageSize: 10,
            totalRecordsCount: page.totalSize,
          }
        : undefined,
    [page],
  )

  const rowDataBound: EmitType<RowDataBoundEventArgs> = (args) => {
    args.row.addEventListener('mouseover', () => {
      setActiveIndex(args.row.rowIndex)
    })
  }

  const handleMouseOut = () => {
    setActiveIndex(undefined)
  }

  useEffect(() => {
    const rows = ref.current?.getRows()
    rows?.forEach((row, index) => {
      for (const cell of row.getElementsByClassName('e-rowcell')) {
        if (activeIndex === index) {
          cell.classList.add('e-hovered', 'e-active')
        } else {
          cell.classList.remove('e-hovered', 'e-active')
        }
      }
    })
  }, [activeIndex])

  const handleCreated: EmitType<Object> = () => {
    ref.current?.element?.addEventListener('mouseleave', handleMouseOut)
  }

  const handleRecordClick: EmitType<RecordClickEventArgs> = (args) => {
    if (!args.rowData.pnu) {
      return
    }

    showLand(args.rowData.pnu, 'registration', undefined, args.rowData.registrationId)
  }

  return (
    <DataGrid
      ref={ref}
      columns={columns}
      dataSource={dataSource}
      pagerModel={pagerModel}
      paginationMode={page ? 'server' : undefined}
      onPageChange={onPageChange}
      loadingIndicator={{ indicatorType: 'Shimmer' }}
      created={handleCreated}
      rowDataBound={rowDataBound}
      recordClick={handleRecordClick}
    />
  )
}

export default RegistrationGrid
