import { forwardRef, useRef } from 'react'
import { useGetClientDataV1ClientsClientIdGet } from '~/libs/apis/data/clients/clients'
import Box from '~/components/Box/Box'
import Typography from '~/components/Typography/Typography'
import { Badge } from '~/components'
import ClientDetailTable from '~/templates/common/detail/ClientDetailTable'
import OccupancyStatus from '~/templates/common/detail/OccupancyStatus'
import { useCombinedRefs } from '@toss/react'
import TopicCommonModal from '~/templates/topic/tenant/common/TopicCommonModal'
import { DialogComponent } from '@syncfusion/ej2-react-popups'
import { useDetailScrollState } from '~/templates/DetailScrollProvider'
import RegistrationOwnership from '~/templates/common/detail/RegistrationOwnership'

interface ClientDetailDialogProps {
  clientId: string
}

const ClientDetailDialog = forwardRef<DialogComponent, ClientDetailDialogProps>(({ clientId }, parentRef) => {
  {
    const dialogRef = useRef<DialogComponent>(null)
    const ref = useCombinedRefs(dialogRef, parentRef)
    const { isTop } = useDetailScrollState()
    const { data } = useGetClientDataV1ClientsClientIdGet(clientId, { swr: { enabled: Boolean(clientId) } })

    const handleOnClose = () => {
      dialogRef.current?.hide()
    }

    const clientDetailTitle = (
      <Box display="flex" flexDirection="column" p="30px 24px" gap="14px">
        <Typography variant="h3" fontWeight="bold" color="gray-800">
          {data?.clientName}
        </Typography>
        {isTop && (
          <Box display="flex" gap="6px">
            {data?.companyTypeName && <Badge type="02" category="purple" size="md" content={data?.companyTypeName} />}
            {data?.clientSector && <Badge type="03" size="md" content={data?.clientSector} />}
          </Box>
        )}
      </Box>
    )

    return (
      <TopicCommonModal ref={ref} visible={false} onClose={handleOnClose} stickyArea={clientDetailTitle}>
        <Box backgroundColor="gray-100" height={12} />
        <ClientDetailTable data={data} />
        <Box backgroundColor="gray-100" height={12} />
        <OccupancyStatus clientIds={clientId} />
        <Box backgroundColor="gray-100" height={12} />
        <RegistrationOwnership clientIds={clientId} />
      </TopicCommonModal>
    )
  }
})

ClientDetailDialog.displayName = 'ClientDetailDialog'

export default ClientDetailDialog
