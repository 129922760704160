import { FC, useEffect, useState } from 'react'
import Typography from '~/components/Typography/Typography'
import Box from '~/components/Box/Box'
import { useLandPriceDataV1GovLandPriceGet } from '~/libs/apis/data/gov/gov'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import { formatDateYm } from '~/libs/utils/date'
import { useTranslation } from 'next-i18next'
import LoadMoreDataGrid from '~/templates/place/detail/LoadMoreDataGrid'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import LandPriceChart from '~/templates/common/chart/LandPriceChart'
import { usePerUnitValueAccessor } from '~/libs/hooks/useFormat'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'
import ScrollBox from '~/components/Box/ScrollBox'
import useLastUpdateDate, { DataId } from '~/libs/hooks/useLastUpdateDate'
import { LandPriceOut, Page } from '~/libs/apis/data/model'
import { ceilToUnit } from '@toss/utils'

interface LandPriceProps {
  pnu: string
}

const PAGE_SIZE = 5

const LandPrice: FC<LandPriceProps> = ({ pnu }) => {
  const { detailType } = useDetailInfo()
  const { lastUpdate } = useLastUpdateDate(undefined, DataId.OFFICIAL_LAND_PRICE)
  const isFull = detailType === 'full'
  const { t } = useTranslation('common', { keyPrefix: 'common_term' })
  const [pageNum, setPageNum] = useState(1)
  const { areaUnit, currencyUnit } = useUserConfiguration()
  const perUnitValueAccessor = usePerUnitValueAccessor(areaUnit, currencyUnit)
  const yearParams = !isFull ? { minYear: (+lastUpdate.year - 5 + 1).toString(), maxYear: lastUpdate.year } : undefined
  const [paginatedData, setPaginatedData] = useState<LandPriceOut[]>([])

  const { data: landPrice } = useLandPriceDataV1GovLandPriceGet(
    {
      pnu,
      ...yearParams,
    },
    {
      swr: {
        keepPreviousData: true,
      },
    },
  )
  const landPriceData = landPrice?.data
  const handlePageChange = (page: number) => setPageNum(page)

  const columnModels: ColumnModel[] = [
    {
      field: 'stdDateYm',
      headerText: t('standard_year_month'),
      textAlign: 'Left',
      width: isFull ? 461 : 110,
      valueAccessor: (_, data: any) => formatDateYm(data.stdDateYm),
    },
    {
      field: 'pricePerM2',
      textAlign: 'Left',
      headerText: t('divide_official_land_price'),
      valueAccessor: perUnitValueAccessor(),
    },
  ]

  const fullTotalSize = landPrice?.page['totalSize']!
  const fullTotalPage = ceilToUnit(fullTotalSize / PAGE_SIZE, 1)
  const pageModel: Page = {
    firstPage: pageNum === 1,
    lastPage: pageNum === fullTotalPage,
    page: pageNum,
    pageSize: PAGE_SIZE,
    totalSize: fullTotalSize,
    totalPage: fullTotalPage,
  }

  const getPageData = (data: LandPriceOut[] | undefined, pageNum: number) => {
    const startIndex = (pageNum - 1) * PAGE_SIZE
    return data?.slice(startIndex, startIndex + PAGE_SIZE)
  }

  useEffect(() => {
    const currentData = getPageData(landPriceData, pageNum)
    setPaginatedData(currentData ?? [])
  }, [landPriceData, pageNum])

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="subtitle" color="gray-800" fontWeight="semibold">
        {t('divide_official_land_price')}
      </Typography>
      <Box display="flex" flexDirection="column" gap="30px">
        <ScrollBox variant="horizontal">
          <LandPriceChart data={landPriceData} chartId={`landPrice_${pnu}_${detailType}`} />
        </ScrollBox>
        <LoadMoreDataGrid
          columns={columnModels}
          dataSource={isFull ? paginatedData : landPriceData}
          pageModel={pageModel}
          onPageChange={handlePageChange}
          allowSelection={false}
        />
      </Box>
    </Box>
  )
}

export default LandPrice
