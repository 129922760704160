/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.3.1
 */

export type AnalysisLandsTradeTrendValueType = typeof AnalysisLandsTradeTrendValueType[keyof typeof AnalysisLandsTradeTrendValueType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnalysisLandsTradeTrendValueType = {
  price_per_area: 'price_per_area',
  count: 'count',
  area: 'area',
  price: 'price',
} as const;
